code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

p {
  margin-bottom: 0;
}
