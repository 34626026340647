.statisticRowTitle {
  margin: 30px 0;
  font-weight: 600;
}

.statisticRow .swiper {
  /* padding: 29px 0; */
  /* width: calc(100% - 52px); */
}

.statisticRow>div {
  background: var(--color-white);
  border-radius: 16px;
  width: 100%;
}

.statisticRow .swiper>div {
  cursor: grab;
}

.statisticRow .swiper>div:active {
  cursor: grabbing;
}

.swiper>div:first-child {
  height: auto;
}

.statBoxItemWrapper {
  display: inline-flex;
  flex-direction: column;
  width: auto;
  cursor: inherit;
}

.statBoxItemWrapper.big .statBoxItem {
  padding: 15px;
  border-radius: 16px;
}

.statBoxItemWrapper.widthBig .statBoxItem {
  min-width: 200px;
}

.classContainer {
  display: grid;
  justify-content: start;
}

@media (max-width: 992px) {
  .statisticRow .swiper {
    padding: 10px 0;
    /* width: calc(100% - 20px); */
  }
}


@media screen and (max-width: 580px) {
  .statisticRowTitle {
    margin: 20px 0 10px 0;
    font-size: 14px;
  }
}


.statisticRowScrollbar {
  margin-left: 4px;
  margin-right: 4px;
  margin-top: 5px;
  background-color: var(--color-blue-gray);
  border-radius: 7px;
  height: 5px;
  cursor: pointer;
}

.statisticRowScrollbar>div {
  background-color: var(--color-accent);
}

.statBoxItem {
  background: var(--color-accent);
  padding: calc(1rem * 0.625) calc(1rem * 0.75);
  text-align: center;
  min-width: 45px;
  width: auto;
  display: inline-block;
  font-size: calc(1rem * 0.875);
  font-weight: 400;
  cursor: inherit;
  margin-right: 1px;
}

.statBoxItemWrapper:first-child .statBoxItem {
  border-top-left-radius: calc(1rem * 0.5);
  border-bottom-left-radius: calc(1rem * 0.5);
  margin-left: 0px;
}

.statBoxItemWrapper:last-child .statBoxItem {
  border-top-right-radius: calc(1rem * 0.5);
  border-bottom-right-radius: calc(1rem * 0.5);
  margin-right: 0px;
}

.purpleZone div {
  color: var(--color-white);
  background: #9683D7;
  color: black;
}

.yellowZone div {
  color: var(--color-black);
  background: var(--color-yellow);
}

.redZone div {
  color: var(--color-black);
  background: #EE7A45;
}

.grayZone div {
  color: var(--color-black);
  background: #E2E8F0;
}

.tooltipContainer {
  display: flex;
  position: fixed;
  visibility: hidden;
  max-width: 295px !important;
  min-height: 129px;
  padding: 10px 40px;
  border-radius: 27px;
  z-index: 10;
  flex-direction: column;
  justify-content: space-between;
}

.tooltipContainer h2 {
  font-size: 14px;
  font-weight: 700;
}

.tooltipContainer p {
  font-size: 14px;
}

@media (max-width: 678px) {
  .statBoxItemWrapper.big .statBoxItem {
    padding: 10px 5px;
    min-width: 150px;
    font-size: 14px;
    border-radius: 12px;
  }
}

@media screen and (max-width: 580px) {
  .statBoxItem {
    min-width: 45px;
  }

  div.statisticRow>div:nth-child(3)>div:nth-child(1) {
    padding: 10px 0;
    width: calc(100% - 20px);
  }

  div.statisticRow>div:nth-child(3)>div:nth-child(1)>div:nth-child(2) {
    margin-top: 10px;
  }
}

@media (max-width: 1280px) {
  .statBoxItem{
    font-size: calc(0.75rem * 1.3);
    padding: calc(0.625rem * 1.3) calc(0.75 * 1.3);
  }
}

@media (max-width: 992px) {
  .statBoxItem{
    font-size: calc(0.75rem * 1.6);
    padding: calc(0.625rem * 1.6) calc(0.75 * 1.6);
  }
}

@media (max-width: 767px) {
  .statBoxItem{
    font-size: calc(0.75rem * 3.3);
    padding: calc(0.625rem * 3.3) calc(0.75 * 3.3);
  }
}