.section {
  background-color: var(--color-blue-gray);
  border-radius: 16px;
  margin-bottom: 30px;
}

.chequeBtn {
  display: grid;
  max-width: 320px;
  font-weight: 500;
  gap: 20px;
}

.ico {
  width: 20px;
  height: 20px;
}

.modalBody {
  margin-bottom: 20px;
}

.title {
  font-weight: 700;
  font-size: 14px;
}


.sectionTitle {
  margin-bottom: 30px;
}

.head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.row {
  position: relative;
  display: grid;
  grid-template-columns: 150px 390px;
  gap: 20px;
  margin-bottom: 20px;
}

.rowTitle {
  font-weight: 600;
  font-size: 14px;
  padding-top: 1.5em;
}

.inputsWrap {
  display: grid;
  grid-template-columns: 1fr 40px;
  gap: 10px;
}

.inputsWrapRow {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.inputsWrapRow>button {
  position: relative;
  top: 0.9em;
}


.checkboxRow {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
}

.checkbox {
  width: 24px;
  height: 24px;
  background-size: 15px 15px;
  border-radius: 5px;
  background-image: none;
}

.rowTitleCheckbox {
  font-weight: 500;
  font-size: 14px;
}

.btnAdd {
  margin-top: 20px;
  margin-bottom: 20px;
}

.imgRow {
  grid-template-columns: 150px 390px 46px;
}

.btnImg {
  width: 46px;
  height: 46px;
  box-shadow: var(--box-shadow-light);
  border-radius: 50%;
  color: var(--color-gray);
  transition: all 0.3s;
  background-color: var(--color-blue-gray);
}

.btnImg>svg {
  width: 12px;
  height: 12px;
}

.btnImg:hover {
  background-color: var(--color-white);
}

.btnImg:active {
  box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}



.list {
  display: grid;
  gap: 30px;
}

.item {
  padding: 30px;
}

.cPatch {
  background-color: #EBEBEB;
}

.bottomBtns {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 30px;
}

.buttonMove svg {
  width: 20px;
  height: 20px;
}