.swiper {
  min-width: 0;
  max-width: 100%;
}

.swiper-slide {
  position: relative;
  overflow: hidden;
}

.swiper.swiper-horizontal .swiper-pagination .swiper-pagination-bullet {
  position: relative;
  width: 4px;
  height: 4px;
  background-color: var(--color-white);
  opacity: 1;
}

.swiper.swiper-horizontal .swiper-pagination .swiper-pagination-bullet-active::after {
  content: '';
  position: absolute;
  inset: -2px;
  border-radius: 50%;
  background-color: var(--color-white);
  opacity: 0.3;
}
