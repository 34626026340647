.inputFormate{
    max-width: 600px;
}
.tiptapClassContent{
    box-shadow: none !important;
    border: 1px solid #e2dfdf;
    border-radius: 8px;
}
.tiptapClassContent::after{
    top: 13px !important;
    left: 13px !important;
}
body .tiptapClassContentProseMirror{
    padding: 13px;
}
body .tiptapClassContentProseMirror >*+* {
    padding-left: 0px;
    margin-top: 3px;
}
.inputBoxSelect{
    border: 1px solid #e2dfdf;
    box-shadow: none !important;
    min-height: 44px;
    border-radius: 8px;
}
.inputBox{
    box-shadow: none !important;
    min-height: 44px;
    border-radius: 8px;
}
input.inputBox, textarea.inputBox{
    padding: 13px;
    border: 1px solid #e2dfdf;
}

.file {
    width: 100%;
    margin-right: 30px;
    box-shadow: none !important;
    border: 1px solid #e2dfdf;
    border-radius: 8px;
    padding: 13px;
    height: fit-content;
  }
.file button svg {
    line-height: 1;
    width: 20px;
    height: 20px;
}
.file label span {
    line-height: 1;
}