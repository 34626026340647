.btn {
  display: inline-flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: var(--ant-border-radius-16);
  min-height: var(--booble-height);
  border: 1px solid transparent;
  color: var(--color-black);
  box-shadow: var(--box-shadow);
  font-weight: 700;
  font-size: 14px;
  padding: 0 32px;
  transition: color 0.3s, background 0.3s, border 0.3s, box-shadow 0.3s;
  cursor: pointer;
  user-select: none;
}

.btn--accent {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.btn--red {
  background-color: var(--color-accent-first);
  color: var(--color-white);
}

.btn--yellow {
  background-color: var(--color-yellow);
}

.btn--violet {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.btn--m {
  min-height: 35px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  padding: 0 20px;
}

.btn.p-0 {
  padding: 0;
}

.btn:disabled,
.btn--disable {
  cursor: default;
  opacity: 0.5;
}

.btn:not(.btn--disableNo):not(:disabled):hover {
  box-shadow: 0 4px 16px var(--color-accent);
}

.btn--accent:not(.btn--disableNo):not(:disabled):hover {
  border-color: transparent;
  color: var(--color-yellow);
}

.btn--red:not(.btn--disableNo):not(:disabled):hover {
  border-color: transparent;
  background-color: var(--color-accent-first);
  color: var(--color-black);
}

.btn--violet:not(.btn--disableNo):not(:disabled):hover {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.btn--yellow:not(.btn--disableNo):not(:disabled):hover {
  border-color: transparent;
  box-shadow: 0 4px 16px var(--color-accent);
}

.btn:not(.btn--disableNo):not(:disabled):active {
  border-color: var(--color-accent);
  box-shadow: var(--box-shadow-active);
}

.btn--accent:not(.btn--disableNo):not(:disabled):active {
  border-color: transparent;
  color: var(--color-yellow);
  box-shadow: var(--box-shadow-active);
}

.btn--red:not(.btn--disableNo):not(:disabled):active {
  border-color: transparent;
  background-color: var(--color-accent-first);
  color: var(--color-black);
  box-shadow: var(--box-shadow-active);
}

.btn--violet:not(.btn--disableNo):not(:disabled):active {
  background-color: var(--color-accent);
  color: var(--color-black);
  box-shadow: var(--box-shadow-active);
}

.btn--yellow:not(.btn--disableNo):not(:disabled):active {
  border-color: transparent;
  background-color: var(--color-yellow);
  color: var(--color-black);

}

.btn:not(.btn--disableNo):not(:disabled):focus-visible,
.focus:not(.btn--disableNo):not(:disabled):focus-visible {
  outline: 2px solid var(--color-accent);
  outline-offset: 4px;
}

@media (max-width: 992px) {
  .btn {
    min-height: 42px;
    font-weight: 600;
    font-size: 12px;
    padding: 0 20px;
  }
}
