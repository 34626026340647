.wrapper {
  display: grid;
  grid-template-columns: 150px 400px;
  gap: 10px;
}

.input {
  width: 100%;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 30px;
}

.btnRemove {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.list {
  display: grid;
  gap: 10px;
}

.row {
  display: grid;
  grid-template-columns: 42px 1fr;
  align-items: center;
  gap: 10px;
  position: relative;
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 16px;
  width: 42px;
  height: 38px;
  box-shadow: var(--box-shadow);
  font-weight: 500;
  font-size: 14px;
}