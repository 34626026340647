.list {
  display: grid;
  gap: 30px;
  margin-bottom: 1rem;
}
.modalClose{
  position: absolute;
  top: 15px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.modal{
  background: #fff;
}