.list {
  margin-top: 10px;
  display: inline-flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 16px;
}

.item {
  font-size: inherit;
  font-weight: inherit;
  word-wrap: break-word;
}

.item.last {
  cursor: default;
}

.item:not(:last-child)::after {
  content: "/";
  margin: 0 0.3em;
}

a.item:hover {
  color: var(--color-accent);
}


@media (max-width: 768px) {
  .list {
    font-size: 14px;
  }
  .item:not(:last-child)::after{
    margin: 0 0.5em;
  }
}
