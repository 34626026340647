
.page {
    width: 100%;
    max-width: 1468px;
    background: var(--color-white);
    z-index: 10;
    height: calc(100vh * 0.79);
    min-height: 60px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.page::before {
    background-color: var(--color-white);
    display: inline-flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-light);
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M3.76024 1.01529C3.9925 0.783025 3.9925 0.406456 3.76024 0.174196C3.52798 -0.0580652 3.15141 -0.0580652 2.91915 0.174196L0.174196 2.91915C-0.0580652 3.15141 -0.0580652 3.52798 0.174196 3.76024L2.91915 6.5052C3.15141 6.73746 3.52798 6.73746 3.76024 6.5052C3.9925 6.27294 3.9925 5.89637 3.76024 5.66411L2.03057 3.93444H11.9694L10.2398 5.66411C10.0075 5.89637 10.0075 6.27294 10.2398 6.5052C10.472 6.73746 10.8486 6.73746 11.0808 6.5052L13.8258 3.76024C14.0581 3.52798 14.0581 3.15141 13.8258 2.91915L11.0808 0.174196C10.8486 -0.0580652 10.472 -0.0580652 10.2398 0.174196C10.0075 0.406456 10.0075 0.783025 10.2398 1.01529L11.9694 2.74496H2.03057L3.76024 1.01529Z' fill='%23AAABC0'/%3E%3C/svg%3E");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    top: calc(100% / 2 - 20px);
}

.header {
    border-bottom: 1px solid var(--color-light-stroke);
    position: relative;
    display: flex;
    align-items: center;
    padding-right: 19px;
    padding-top: 1px;
}

.search {
    width: 97%;
    background: var(--color-white) !important;
    font-size: 14px !important;
    color: var(--color-text-main) !important;
    font-weight: 400 !important;
    box-shadow: none !important;
    padding: 20px 43px !important;
}

.search button {
    position: absolute;
    left: 16px;
    top: 16px;
    width: 20px;
    height: 20px;
}

.search button > svg{
    width: 20px;
    height: 20px;
    fill: #C5C6D9;
}

.closeIcon {
    position: absolute;
    right: 0;
}

.addBtn {
    position: relative;
    display: block;
}

.addBtn svg path {
    fill:#C5C6D9;
    transition: fill .3s ease;
}

.addBtn:hover svg path {
    fill:#B2ADB5;
}

.desc {
    opacity: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: opacity .3s ease;
    width: 130px;
    color: var(--color-text-main);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 44px;
    font-size: 14px;
    position: absolute;
    right: 36px;
    bottom: -20px;
    padding: 3px 6px 1px 6px ;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="64" height="22" viewBox="0 0 64 22" fill="none"><mask id="path-1-outside-1_375_7960" maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="22" fill="black"><rect fill="white" width="64" height="22"/><path fill-rule="evenodd" clip-rule="evenodd" d="M32.154 1.33607C31.8059 0.887976 31.1941 0.887975 30.846 1.33607L28 5H9C4.58172 5 1 8.58172 1 13C1 17.4183 4.58172 21 9 21H55C59.4183 21 63 17.4183 63 13C63 8.58172 59.4183 5 55 5L35 5L32.154 1.33607Z"/></mask><path fill-rule="evenodd" clip-rule="evenodd" d="M32.154 1.33607C31.8059 0.887976 31.1941 0.887975 30.846 1.33607L28 5H9C4.58172 5 1 8.58172 1 13C1 17.4183 4.58172 21 9 21H55C59.4183 21 63 17.4183 63 13C63 8.58172 59.4183 5 55 5L35 5L32.154 1.33607Z" fill="white"/><path d="M30.846 1.33607L30.2932 0.906662L30.2932 0.906663L30.846 1.33607ZM32.154 1.33607L32.7068 0.906663L32.7068 0.906662L32.154 1.33607ZM28 5V5.7H28.3426L28.5528 5.42941L28 5ZM55 5V4.3V5ZM35 5L34.4472 5.42941L34.6574 5.7H35V5ZM31.3988 1.76548C31.4463 1.70432 31.4859 1.7 31.5 1.7C31.5141 1.7 31.5537 1.70432 31.6012 1.76548L32.7068 0.906662C32.0785 0.0977792 30.9215 0.0977791 30.2932 0.906662L31.3988 1.76548ZM28.5528 5.42941L31.3988 1.76548L30.2932 0.906663L27.4472 4.57059L28.5528 5.42941ZM9 5.7H28V4.3H9V5.7ZM1.7 13C1.7 8.96832 4.96832 5.7 9 5.7V4.3C4.19512 4.3 0.3 8.19512 0.3 13H1.7ZM9 20.3C4.96832 20.3 1.7 17.0317 1.7 13H0.3C0.3 17.8049 4.19512 21.7 9 21.7V20.3ZM55 20.3H9V21.7H55V20.3ZM62.3 13C62.3 17.0317 59.0317 20.3 55 20.3V21.7C59.8049 21.7 63.7 17.8049 63.7 13H62.3ZM55 5.7C59.0317 5.7 62.3 8.96832 62.3 13H63.7C63.7 8.19512 59.8049 4.3 55 4.3V5.7ZM35 5.7L55 5.7V4.3L35 4.3V5.7ZM31.6012 1.76548L34.4472 5.42941L35.5528 4.57059L32.7068 0.906663L31.6012 1.76548Z" fill="%23F4F4F4" mask="url(%23path-1-outside-1_375_7960)"/></svg>');
}

.addBtn:hover + .desc {
   opacity: 1;
}

.chats {
    height: 100%;
    /*overflow: auto;*/
}

.close {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 16px;
    background: var(--color-white);
    z-index: 10;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.close svg {
    transition: fill .3s ease;
}

.close:hover svg{
    fill: var(--color-black);
}

.order{
    display: flex;
    flex-direction: column;
}

.tabs{
    padding: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

body .tabActive{
    background: #AB48E0 !important;
    color: white !important;
}