.chat {
    padding: 12px 16px;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--color-light-stroke);
    display: flex;
    gap: 16px;
    transition: background-color .3s ease, border-color .3s ease;
    position: relative;
    cursor: pointer;
}

.defaultImage {
    width: 48px;
    height: 48px;
    background: var(--color-light-stroke);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-top: 4px;
}

.info {
    margin-left: auto;
}

.title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
    min-height: 16px;
}

.message {
    font-size: 12px;
    color: var(--color-text-secondary);
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.chatBody, .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.info {
    align-items: self-end;
    justify-content: center;
}

.date {
    font-size: 8px;
    line-height: 1.5;
}

.unread {
    width: 14px;
    height: 14px;
    background: #AB48E0;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 9px;
    font-weight: 600;
}

.clip {
    transition: opacity .3s ease;
    display: inline-flex;
    align-items: center;
}

.infoFooter {
    display: flex;
    align-items: center;
}

.dots {
    opacity: 0;
    transition: opacity .3s ease;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

@media (min-width: 768px) {
    .chat:hover {
        background: #EDE0FF;
        border: 1px solid #EDE0FF;
    }
    
    .chat:hover .dots {
        opacity: 1;
    }
}
@media (max-width: 767px) {
    .chat .dots {
        opacity: 1;
    }
}

.menu {
    padding: 0 16px;
    border-radius: 6px;
    flex-direction: column;
    border: 1px solid var(--color-light-stroke);
    display: none;
    position: absolute;
    right: 16px;
    bottom: -54px;
    z-index: 10;
    background: var(--color-white);
}

.active {
    display: flex;
}

.menuName {
    font-size: 12px;
}

.menuIcon {
    width: 16px;
    height: 16px;
}

.menuRow {
    padding: 12px 0;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: var(--color-white);
    transition: color .3s ease;
    cursor: pointer;
}

.menuRow + .menuRow {
    border-top: 1px solid var(--color-light-stroke);
}

.menuRow svg path {
    transition: fill .3s ease;
}

.menuRow:hover {
    color: #AB48E0;
}

.menuRow:hover svg path {
    fill: #AB48E0;
}

.photoMember.photoMember {
    margin-top: 0;
    width: 24px;
    height: 24px;
}

.photoMember img {
    width: 24px;
    height: 24px;
}

.infoMember {
    display: flex;
    align-items: center;
    gap: 8px;
}

.messageDz{
    display: flex;
    flex-direction: column;
}
.messageDz a{
    text-decoration: underline;
    color: var(--color-accent);
}