.booble {
  min-width: 246px;
  display: inline-block;
  background-color: white;
  border-radius: 16px;
  padding: 13px 20px;
  margin: 0 5px;
  box-shadow: var(--box-shadow-light);
  font-size: 14px;
  line-height: 18px;
  border: 1px solid transparent;
}

.text {
  font-weight: 300;
  font-size: 18px;
  line-height: 60px;
  margin-bottom: 20px;
}

.textCorrection {
  margin: 0;
}

.text > span {
  font-weight: inherit;
}

.wrapper {
  display: inline-block;
  position: relative;
}

.wrapper > .booble:last-child {
  left: 0;
  top: 0;
  position: absolute;
  visibility: hidden;
}

.accent {
  border-color: var(--color-accent);
}

.red {
  border-color: var(--color-accent-first);
}


.answerWrap, .answerWrapTask {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}

.answerWrapTask {
  margin-bottom: 20px;
}

.capsuleLeft, .capsuleRight {
  width: auto;
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 150px;
  border-radius: 16px ;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.capsuleLeft {
  width: auto;
  position: relative;
  z-index: 2;
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
}

.capsuleRight {
  position: relative;
  z-index: 1;
  background-color:white;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;
}

.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
}

.boobleSpent {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}
@media (max-width: 768px) {
  .booble {
    min-width: 221px;
    padding: 5px 10px;
    margin: 0 2px;
    font-size: 12px;
    line-height: auto;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    margin-bottom: 20px;
  }
}
.textWrap{
  white-space: pre-wrap;
}