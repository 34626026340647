/* фон банера */

.accent {
  background-color: var(--color-accent);
}

.accentFirst {
  background-color: var(--color-accent-first);
}

.blueGray {
  background-color: var(--color-blue-gray);
}

.gray {
  background-color: var(--color-gray);
}

.white {
  background-color: var(--color-white);
}
.violet {
  background-color: var(--color-violet);
}
.violetDark {
  background-color: var(--color-violet-dark);
}

.blueDeep{
  background-color: var(--color-blue-deep);
} 

.yellowDeep{
  background-color: var(--color-yellow-deep);
}

.pinkDeep{
  background-color: var(--color-pink-deep);
}

.greenDeep{
  background-color: var(--color-green-deep);
}