.userInfo {
  display: flex;
  align-items: center;
  gap: 12px;
}

.logoWrapper {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  flex-shrink: 0;
}

.wrapper {
  padding: 16px;
  position: fixed;
  border-radius: 12px;
  border: 1px solid var(--color-light-stroke);
  top: 16px;
  left: 16px;
  bottom: 0;
  width: var(--width-sidebar);
  z-index: 100;
  background: var(--color-white);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.userData {
  width: 80%;
  position: relative;
}

.avatar {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  object-fit: cover;
  width: 48px;
  height: 48px;
}

.fio {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 4px 0;
  max-width: 88%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mail {
  color: #535763;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.12px;
}

.linkEdit {
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
}

.navList {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navLink {
  display: flex;
  align-items: center;
  padding: 16px 10px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-text-main);
  position: relative;
  transition: background .3s ease, box-shadow .3s ease, border .3s ease;
  border: 1px solid var(--color-white);
}

.navLink:hover {
  border: 1px solid var(--color-stroke-hover);
  box-shadow: 0px 2px 0px 0px rgba(114, 88, 255, 0.14);
}

.navLink.active {
  background: linear-gradient(266deg, #FFF -18.11%, #FBF9FF 68.74%);
  box-shadow: 0px 2px 0px 0px rgba(202, 193, 255, 0.14);
}

.navLink svg {
  fill: #C5C6D9;
}

.navIco {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}

.notice {
  margin-left: auto;
  display: inline-flex;
  align-items: center;
  background: #AB48E0;
  padding: 4px 7px;
  width: 20px;
  border-radius: 4px;
  height: auto;
  font-size: 10px;
  font-weight: 600;
}

.sidebarFooter {
  margin-top: auto;
}

.support {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding-top: 12px;
  border-top: 1px solid var(--color-light-stroke);
}

.support svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 992px) {
  .wrapper {
    position: fixed;
    padding: 10px 0 0;
    box-shadow: var(--box-shadow);
    border-radius: 0 15px 15px 0;
    transform: translateX(-120%);
    transition: transform 0.5s;
    height: calc(100% - 66px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .wrapper.show {
    transform: translateX(0);
  }

  .logoWrapper {
    padding: 0 60px;
    margin-bottom: 25px;
  }

  .userInfo {
    display: none;
  }

  .notice {
    height: 20px;
  }

  .navList {
    gap: 14px;
  }

  .navLink {
    font-size: 10px;
    padding: 0 4px;

  }
}
@media (max-width: 540px) {
  .navLink.active {
    border: 0px solid var(--color-white) !important;
  }
  .navLink {
    width: calc(100% - 8px);
    font-size: 14px; 
    border-radius: 8px;
    padding: 8px;
  }
  .notice {
   margin-right: -8px;
  }
}

.customId{
  padding-left: 0.5rem;
}