.wrapper {
  width: 30px;
  height: 30px;
  border: 2px solid var(--color-accent);
  border-radius: 5px;
  cursor: pointer;
  background-size: 17px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 469.184 469.185'%3e%3cpath d='m462.5 96.193-21.726-21.726c-8.951-8.95-23.562-8.95-32.59 0L180.368 302.361l-119.34-119.34c-8.95-8.951-23.562-8.951-32.589 0L6.712 204.747c-8.95 8.951-8.95 23.562 0 32.589L163.997 394.62c4.514 4.514 10.327 6.809 16.218 6.809s11.781-2.295 16.219-6.809L462.27 128.783c9.18-9.103 9.18-23.638.23-32.59z'/%3e%3c/svg%3e");
}

.wrapper.active {
  background-color: var(--color-accent);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 469.184 469.185'%3e%3cpath d='m462.5 96.193-21.726-21.726c-8.951-8.95-23.562-8.95-32.59 0L180.368 302.361l-119.34-119.34c-8.95-8.951-23.562-8.951-32.589 0L6.712 204.747c-8.95 8.951-8.95 23.562 0 32.589L163.997 394.62c4.514 4.514 10.327 6.809 16.218 6.809s11.781-2.295 16.219-6.809L462.27 128.783c9.18-9.103 9.18-23.638.23-32.59z'/%3e%3c/svg%3e");
}

@media (max-width: 992px) {
  .wrapper {
    width: 25px;
    height: 25px;
    border-radius: 6px;
    background-size: 15px;
    size: 17px;
  }
}


.btn {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.checkboxLabel {
  width: 1.2em;
  height: 1.2em;
  border-radius: 0.3em;
  background-size: 0.8em;
  background-image: none;
}

.disabled {
  cursor: default;
}
