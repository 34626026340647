.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: var(--color-white);
    z-index: 10;
    height: 682px;
    border-radius: 12px;
    border: 1px solid var(--color-light-stroke);
}

.pageForm {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.4;
}


.close {
    position: absolute;
    right: 16px;
    top: 16px;
}

.close svg {
    width: 24px;
    height: 24px;
}

.header {
    padding: 18px 16px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.participants {
    height: 48px;
    padding: 12px 16px;
    display: flex;
    gap: 8px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.inputFile {
    width: fit-content;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.inputFile svg {
    cursor: pointer;
}

.inputFile label span {
    font-size: 12px;
    padding: 0;
    max-width: 84px;
    word-break: break-word;
    color: var(--color-text-main);
}

.inputFile svg path {
   transition: fill .3s ease;
}

.inputFile:hover svg path {
    fill: var(--color-text-main);
}

.inputGrey {
    border: none;
    box-shadow: none;
    padding: 0;
    width: 100%;
}

.inputGrey:hover{
    box-shadow: none!important;
}

.multipleSelect {
    padding: 0 16px;
}

.users {
    max-height: 500px;
    overflow-y: auto;
    /*padding-bottom: 72px;*/
}


.footer {
    box-shadow: 0px -10px 8px -4px #02061708;
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--color-white);
}

.submitBtn {
    display: block;
    margin-left: auto;
    font-size: 14px;
    padding: 8px 16px;
    background: #AB48E0;
    border-radius: 8px;
    min-width: 78px;
    border: 1px solid #AB48E0;
    color: var(--color-white);
}

.submitBtn:disabled {
    cursor: no-drop;
    border: 1px solid var(--color-light-stroke);
    color: var(--color-text-main);
    background: var(--color-light-stroke);
}

.multipleSelect {
    padding: 20px 16px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.participant {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 8px;
    padding: 2px 4px;
    border-radius: 4px;
    background: #FBF4FF;
}
.participant + .participant {
    margin-left: 8px;
}

.participantDelBtn {
    cursor: pointer;
}

.participantDelBtn:hover svg path{
    fill: #AB48E0;
}
.input {
    margin-left: 8px;
    position: relative;
    border: none;
    width: 100%;
}

.inputSearch {
    width: auto;
    margin-left: 8px;
    position: relative;
    border: none;
}

.label {
    position: absolute;
    width: 100%;
    left: 20px;
    z-index: 20;
    color: var(--color-text-secondary);
    font-size: 14px;
}

.rowName {
    font-size: 16px;
    color: var(--color-text-main);
}

.submitBtn.center {
    margin-left: auto;
}

@media (max-width: 540px) {
    .footer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .inputWr {
        display: flex;
    }

    .submitBtn {
        margin-left: 0;
        margin-top: 8px;
    }
}