.page {
    padding: 12px 24px 12px 32px;
    border-radius: 12px;
    box-shadow: 0px 8px 8px -4px rgba(2, 6, 23, 0.03), 0px 20px 24px -4px rgba(2, 6, 23, 0.08);
    display: flex;
    height: 60px;
}

.openIcon {
    position: absolute;
    left: 4px;
    top: 4px;
    cursor: pointer;
}

.openIcon svg path {
    fill:#B2ADB5;
    transition: fill .3s ease;
}

.openIcon:hover svg path {
    fill: var(--color-text-main);
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.defaultImage {
    width: 32px;
    height: 32px;
    background: var(--color-light-stroke);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.chats {
    display: flex;
}

.unreadEl {
    position: relative;
    cursor: pointer;
    transition: filter .3s ease;
    border-radius: 50%;
}

.unreadEl:hover{
    filter: brightness(0.95);
}

.unreadEl + .unreadEl {
    margin-left: -4px;
}


.purple {
    position: absolute;
    top: 0px;
    right: 0;
    width: 14px;
    height: 14px;
    color: var(--color-white);
    background: #AB48E0;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 9px;
}

.messages {
    position: relative;
    cursor: pointer;
}

.wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.chatImage {
    margin-top: 0;
    width: 32px;
    height: 32px;
}

.chatImage img {
    width: 32px;
    height: 32px;
}