.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
 }

.list {
  display: grid;
  gap: 10px;
  max-width: 600px;
}

.row {
  position: relative;
  text-align: center;
}

.setSizeWpapper{
  display: flex;
  flex-direction: column;
  gap: 3px;
  position: absolute;
  right: -140px;
  top: 0px;
}
.sizeInput {
  width: 50px;
  box-shadow: none;
  border: 1px solid #e2dfdf;
  padding: 5px;
  text-align: center;
}
.sizeContainer{
  display: flex;
  align-items: center;
  gap: 3px;
}
.sizeInput::-webkit-outer-spin-button,
.sizeInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sizeSpan {
  font-size: 13px;
}