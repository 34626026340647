.wrapper {
  padding: 40px;
  padding-bottom: 0;
  justify-content: flex-end;
  gap: 20px;
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 50%;
  box-shadow: var(--box-shadow);
  transition: box-shadow 0.3s;
}
.buttonNewDesin{
  position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}

.button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 4px 16px var(--color-accent);
}

.button span {
  position: absolute;
  top: -5px;
  right: -13px;
}

.buttonQuit {
  background-color: var(--color-accent);
  color: var(--color-white);
  border-radius: 14px;
}

.avatar {
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.avatar img {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
}

.logo {
  width: 100px;
  height: 30px;
  margin: 0 auto;
}

.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.logoDraw {
  width: 200px;
  height: 60px;
  margin: 0 auto;
}

.logoDraw img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}


@media (max-width: 1280px) {
  .wrapper {
    padding: 20px;
    padding-bottom: 0;
    gap: 15px;
  }
}

@media (max-width: 992px) {
  .wrapper {
    padding: 0;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 81px 1fr 81px;
    align-items: center;
    grid-template-rows: repeat(1, 1fr);
    /* grid-row-gap: 0; */
    margin-bottom: 0;
    grid-row-start: 1;
    grid-column-start: 1;
  }

  .burger {
    width: 32px;
    height: 32px;
  }



  .photo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    background: linear-gradient(134.01deg, var(--color-accent) 38.91%, var(--color-accent-first) 94.5%);
    border-radius: 50%;
  }

  .photo::before {
    position: absolute;
    content: '';
    width: 55px;
    height: 55px;
    background-color: var(--color-dark);
    border-radius: 50%;
  }

  .photo.active::after {
    content: '';
    inset: 0;
    position: fixed;
    z-index: 2;
  }
}

@media (max-width: 400px) {
  .wrapper {
    grid-template-columns: 55px 1fr 55px;
  }

  .photo {
    width: 55px;
    height: 55px;
  }

  .photo::before {
    width: 52px;
    height: 52px;
  }

  .avatar {
    width: 52px;
    height: 52px;
  }
}

.settingWrapper {
  display: flex;
  justify-content: flex-end;
}





.modalQuit {
  min-width: 12px;
  font-size: 12px;
  margin-left: auto;
}



.modalList button,
.modalList a {
  display: block;
  text-align: start;
  width: 100%;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-gray);
}

.modalList svg {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}







/* Drawer */
.navList {
  margin-left: -24px;
  margin-right: -14px;
}

/* Footer */
.balance {
  display: block;
  z-index: 1;
  background-color: #e6ecf2;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-black);
  padding: 8px 5px;
  text-align: center;
  margin-top: -10px;
  margin-left: -16px;
  margin-right: -16px;
}

.balance span {
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray);
}
