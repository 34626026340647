.mathInputWrapper {
  position: relative;
}

.mathInput {
  border: 1px dashed var(--color-gray);
  width: calc(100% - 30px);
  margin-bottom: 15px;
  min-height: 90px;
}

.mathKeyboard {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  min-height: 20px;

  z-index: 1;
  background-color: #F6F6F6;
  border-radius: 16px;
  padding: 10px;
  box-shadow: var(--box-shadow-light);
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.mathKeyboard button {
  height: 24px;
  border-radius: 5px;
}

.mathKeyboard button:hover {
  box-shadow: var(--box-shadow-light);
}

.mathKeyboard button:active {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.mathLatex {
  /* border: 1px dashed var(--color-gray); */
  cursor: pointer;
}

.mathLink {
  position: relative;
  z-index: 2;
  color: var(--color-accent);
  margin-left: auto;
  font-size: 12px;
  user-select: none;
  cursor: pointer;
}