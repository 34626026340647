.page {
    width: 100%;
    background: var(--color-white);
    z-index: 10;
    height: calc(100vh * 0.796);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
}

.page::before {
    background-color: var(--color-white);
    display: inline-flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-light);
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M3.76024 1.01529C3.9925 0.783025 3.9925 0.406456 3.76024 0.174196C3.52798 -0.0580652 3.15141 -0.0580652 2.91915 0.174196L0.174196 2.91915C-0.0580652 3.15141 -0.0580652 3.52798 0.174196 3.76024L2.91915 6.5052C3.15141 6.73746 3.52798 6.73746 3.76024 6.5052C3.9925 6.27294 3.9925 5.89637 3.76024 5.66411L2.03057 3.93444H11.9694L10.2398 5.66411C10.0075 5.89637 10.0075 6.27294 10.2398 6.5052C10.472 6.73746 10.8486 6.73746 11.0808 6.5052L13.8258 3.76024C14.0581 3.52798 14.0581 3.15141 13.8258 2.91915L11.0808 0.174196C10.8486 -0.0580652 10.472 -0.0580652 10.2398 0.174196C10.0075 0.406456 10.0075 0.783025 10.2398 1.01529L11.9694 2.74496H2.03057L3.76024 1.01529Z' fill='%23AAABC0'/%3E%3C/svg%3E");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    top: calc(100% / 2 - 20px);
}

.header {
    padding: 16px;
    border-bottom: 1px solid var(--color-light-stroke);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.headerTitle {
    display: flex;
    gap: 8px;
    align-items: center;
}

.close {
    cursor: pointer;
}

.close svg path {
    transition: fill .3s ease;
}

.close:hover svg path {
    fill: #000;
}

.info {
    padding: 20px 16px;
    border-top: none;
    border-bottom: none;
    gap: 12px;
    display: flex;
    align-items: center;
    position: relative;
}

.tabs {
    display: flex;
    gap: 12px;
    padding: 0 16px;
    border: 1px solid var(--color-light-stroke);
    border-top: none;
}

.tab {
    padding: 8px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
}

.active {
    border-bottom: 1px solid var(--color-accent);
}

.title {
    font-weight: 600;
}

.title.title input {
    font-weight: 600;
    font-size: 20px;
}

.numbers {
    color: #32353E;
    font-size: 12px;
}

.image {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-light-stroke);
}

.exit {
    font-size: 14px;
    padding: 16px;
    text-align: center;
    margin-top: auto;
    background: #FAFAFB;
    cursor: pointer;
    transition: background-color .3s ease;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

.exit:hover {
    background-color: #EDE0FF;
}

.title.tile input .ant-input {
    box-shadow: none;
}
.input.input .ant-input  {
    box-shadow: none;
}

.input.input:hover div {
    box-shadow: none;
}

.input.input div:active  {
    border: none;
}

.input.input div input {
    padding-left: 0;
    font-weight: 600;
}

.input.input div input::placeholder {
    font-weight: 600;
}

.addBlock {
    padding: 16px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.addElem {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.label {
    transition: color .3s ease;
    color: var(--color-text-main);
}

.addElem:hover .label{
   color: #000;
}

.addElem:hover .plus{
    background: #EDE0FF;
}

.addElem:hover .plus svg path{
    fill: var(--color-accent);
}

.plus {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: var(--color-light-stroke);
    transition: background-color .3s ease;
}

.inputFile {
    background: var(--color-white);
    height: 24px;
    opacity: 0;
    transition: opacity .3s ease;
    position: absolute;
    z-index: 10;
    width: 24px;
    top: 32px;
    border-radius: 50%;
    left: 46px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
}

.info:hover .inputFile{
    opacity: 1;
}

.inputFile svg {
    cursor: pointer;
}

.edit {
    position: absolute;
    cursor: pointer;
    border: 1px solid var(--color-light-stroke);
    background: var(--color-white);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    left: 48px;
    bottom: 60px;
    transition: background-color .3s ease;
}

.edit:hover{
    background: var(--color-light-stroke);

}

@media (max-width: 540px) {
    .title.title input {
        font-size: 16px;
    }
}