.list {
  display: grid;
  max-width: 600px;
  gap: 5px;
  margin-bottom: 20px;
}

.link {
  display: block;
  position: relative;
  color: #287abe;
  text-decoration: underline;
}

.link img {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 768px) {
  .list {
    margin-bottom: 10px;
  }
}
