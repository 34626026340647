.filter, .sort {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .search {
    max-width: 300px;
    flex: 1 0;
  }
  
  .list {
    display: grid;
    gap: 30px;
  }
  
  
  @media (max-width: 768px) {
    .filter {
      gap: 10px;
      margin-bottom: 10px;
    }
  
    .list {
      gap: 10px;
    }
  
  }
  