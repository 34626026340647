.title {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray);
  margin-bottom: 20px;
}

.text {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.list {
  display: grid;
  justify-items: flex-start;
  gap: 15px;
}

@media (max-width: 768px) {
  .title {
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
  }
}