.preloader {
    display: flex;
    justify-content: center;
}

.title {
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 40px;
}

.subtitle {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

.img {
  max-height: 500px;
}

@media (max-width: 991px) {
  .wrapper {
    margin: 0 auto;
    padding: 15px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .subtitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 0;
  }
}