.message {
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.messageWr {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    transition: background-color .3s ease;
    cursor: pointer;
    position: relative;
}

.myMessage {
    background-color: #f9f8f9;
}

.message:hover {
    background-color: #EDE0FF;
}

.chatBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.authorName {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-text-main);
    line-height: 2;
}

.text {
    font-size: 12px;
    color: var(--color-text-secondary);
    line-height: 1.6;
}

.chatHead {
    display: flex;
    align-items: center;
    gap: 12px;
}

.date {
    font-size: 11px;
    font-weight: 500;
    color: var(--color-text-secondary);
}

.dots {
    opacity: 0;
    transition: opacity .3s ease;
    cursor: pointer;
    display: inline-flex;
    align-items: flex-start;
    margin-left: auto;
}

.message:hover .dots {
    opacity: 1;
}

.menu {
    padding: 0 16px;
    border-radius: 6px;
    flex-direction: column;
    border: 1px solid var(--color-light-stroke);
    display: none;
    position: absolute;
    right: 16px;
    top: 28px;
    z-index: 10;
    background: var(--color-white);
}
.menuLeft{
    left: 16px;
    right: auto;
    width: fit-content;
}
.active {
    display: flex;
}

.menuName {
    font-size: 12px;
}

.menuIcon {
    width: 16px;
    height: 16px;
}

.menuRow {
    padding: 12px 0;
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: var(--color-white);
    transition: color .3s ease;
    cursor: pointer;
}

.menuRow + .menuRow {
    border-top: 1px solid var(--color-light-stroke);
}

.menuRow svg path {
    transition: fill .3s ease;
}

.menuRow:hover {
    color: #AB48E0;
}

.menuRow:hover svg path {
    fill: #AB48E0;
}

.photo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.isOnline {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    background: #52FF97;
    right: 0;
    bottom: 0;
}

.fixed {
    position: fixed;
    top: 58px;
    background: var(--color-white);
}

.fixed .photo{
    display: none;
}

.messageImages {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.messageImage {
    max-width: 70%;
}

.messageLink {
    color: var(--color-accent);
    font-size: 14px;
    transition: filter .3s ease;
}

.messageLink:hover{
    filter: brightness(0.9);
}

.answer {
    padding: 8px 12px;
    border-radius: 12px;
    border: 1px solid var(--color-light-stroke);
    background: var(--color-light-stroke);
    width: 100%;
}

.answerAuthor {
    margin: 0 0 8px 0;
    font-weight: 600;
    font-size: 12px;
}

.answerText {
    font-size: 12px;
}

.clipMessageWr {
    display: flex;
    gap: 4px;
    align-items: center;
    position: relative;
}

.clipMessageText {
    color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

@media (max-width: 540px) {
    .message {
        padding: 12px;
    }

    .menu {
        padding: 0 8px;
        right: 0;
    }
    .menuLeft{
        left: 0px;
    }

    .menuRow {
        padding: 8px 0;
    }

    .menuName {
        font-size: 10px;
    }

    .authorName {
        line-height: 1.2;
        font-size: 12px;
    }
}
.messageDz{
    display: flex;
    flex-direction: column;
}
.messageDz a{
    text-decoration: underline;
    color: var(--color-accent);
}