.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-bottom: 30px;
}

.estimation>*:first-child {
  width: 200px;
}

.answers {
  margin-bottom: 40px;
  display: grid;
  gap: 25px;
}

.answers button {
  width: 200px;
}


.selectScore {
  max-width: 150px;
  width: 100%;
  text-align: center;
}

.answersWrap:not(.show) {
  display: none;
}

.answersWrap p {
  max-width: 600px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}

.answersList {
  display: grid;
  gap: 10px;
  max-width: 600px;
}

.answersLink {
  position: relative;
}

.answers.red .booble {
  background-color: var(--color-accent-first);
  color: var(--color-white);
}

.answers.green .booble {
  background-color: var(--color-green);
  color: var(--color-white);
}

.textareaWrap {
  display: inline-block;
  position: relative;
  max-width: 510px;
  width: 100%;
}


.textarea,
.textarea~div {
  padding-right: 60px;
  min-height: 100px;
}

.uploadImg {
  position: absolute;
  top: 10px;
  right: 10px;
}

.btnImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  box-shadow: var(--box-shadow-light);
  border-radius: 50%;
  color: var(--color-gray);
  transition: all 0.3s;
  background-color: var(--color-blue-gray);
}

.btnImg>svg {
  width: 12px;
  height: 12px;
}

.btnImg:hover {
  background-color: var(--color-white);
}

.btnImg:active {
  box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}

.file {
  display: grid;
  gap: 10px;
  margin-top: 20px;
}

.file>span {
  display: grid;
  gap: 10px;
  grid-template-columns: 300px 23px;
}

.file>span>a {
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 992px) {
  .booble {
    min-height: 35px;
    font-weight: 600;
    font-size: 12px;
  }
}