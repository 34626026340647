.modal__background {
  position: fixed;
  z-index: 101;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 20px 15px;
  background: rgba(255 255 255 / 70%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  backdrop-filter: blur(6px);
  transform: translate3d(0, 0, 0);
  -webkit-backdrop-filter: blur(6px);
  -webkit-transform: translate3d(0, 0, 0);
  inset: 0;
  color: var(--color-black);


}

.modal__background::before {
  position: absolute;
  content: "";
  inset: 0;
  /* background-color: rgba(0, 0, 0, 0.9); */
  filter: blur(5px);
}

.modal__content {
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  border-radius: 24px;
  padding: 45px 65px;
  min-width: 40%;
  box-shadow: var(--box-shadow);
  background-color: var(--color-smoke);
  transform: scale(0.5);
  transition: 0.5s;
}

.modal__background.active {
  opacity: 1;
  visibility: visible;
}

.modal__background.active .modal__content {
  transform: scale(1);
}

.modal__title {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 24px;
  line-height: 163.42%;
}

.modal__description {
  font-weight: 400;
  font-size: 16px;
  line-height: 163.42%;
  margin-bottom: 20px;
}

@media (max-width: 1279px) {
  .modal__content {
    padding: 25px;
  }
}

@media (max-width: 992px) {
  .modal__background {
    padding-bottom: 80px;
  }

  .modal__content {
    padding: 18px;
    min-width: min(500px, 100%);
  }

  .modal__title {
    font-size: 14px;
  }

  .modal__description {
    font-size: 12px;
  }
}

@media (max-width: 678px) {
  .modal__content.fullScreenMobile {
    width: auto;

    /* width: 100%; */
  }
}

.modalProfileIconClose{
  position: absolute;
  top: 18px;
  right: 18px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.modalContainerWrapper{
  margin-top: 30px;
}