.wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #F4F4F4;
  border-left: 1px solid #F4F4F4;
  border-right: 1px solid #F4F4F4;

  border-radius: 15px 15px 0px 0px;
  color: var(--color-white);
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  z-index: 100;
  white-space: nowrap;
  box-shadow: 0px 20px 24px -4px #02061714;
}
.wrapper span{
  font-size: 12px;
  color: #32353E80;
}
.wrapper svg {
  fill: #C5C6D9;
}
.wrapper a:not(.active) svg path{
  fill: #C5C6D9;
}
.list {
  display: flex;
  justify-content: space-around;
}

.link {
  gap: 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.link.active span {
  color:#2B2D3E;
}
.link.active svg {
  fill:#724789;
}
.link svg {
 width: 20px;
 height: 20px;
}

@media (min-width: 580px) {
  .wrapper {
    padding: 0 10%;
    font-size: 14px;
  }
}
