.imageContainer {
  max-height: 1000px;
  height: 100%;
  overflow: auto;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
}
.imageContainerFullScreen {
  max-height: 92vh;
  overflow: auto;
  object-fit: contain;
}

.imageContainer a {
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 600px;
}
.imageContainer canvas {
  object-fit: contain;
  width: 100% !important;
  height: 100% !important;
}
.icoFull{
  width:25px;
  height:25px;
  object-fit: contain;
}
.imageContainerFullScreen a {
  display: flex;
  justify-content: center;

  height: 100%;
}
.imageContainerFullScreen canvas {
  object-fit: contain;
}
.imageContainerFullScreen a  #canvas{
  width: 100%;
  height: 100%;
}
.kimContainer {
  margin: 0 auto;
  display: flex;
  gap: 15px;
  width: 1160px;
}

.kimTaskHeader {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.tableKim table {
  border-collapse: collapse;
  border: 1px solid #B9B9B9;
}

.tableKim tr td {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  border: 1px solid #B9B9B9;
  background-color: rgba(255, 255, 255, 0.05);
}


.kimFirstColumn {
  background-color: #EFEFEF;
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  width: 30px;
  text-align: center;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 30px;
}

.footerKim {
  display: block;
  width: 100%;
  margin-top: 30px;
}

.btnUpload {
  display: flex;
  margin: 0 auto;
  max-width: 1150px;
  width: 100%;
}

.footerSend>button {
  margin-top: 30px;
  background-color: #ED6B6A;
  color: var(--color-white);
}

.buttonPrev {
  margin-bottom: 0;
}

.kimOverlay {
  overflow: auto;
}

.title {
  margin-top: 80px;
  text-align: center;
  font-weight: 400;
  font-size: 75px;
}

.time {
  font-weight: 400;
  font-size: 36px;
  margin-left: auto;
}

.showBottom {
  margin: 30px 0;
}

.commentList {
  display: grid;
  gap: 10px;
}

.balls {
  margin: 10px 0 30px;
}

.buttonPdf {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 10px;
}

.buttonPdf span {
  min-width: 130px;
  text-align: center;
}

.buttonPdf svg {
  width: 24px;
  height: 24px;
}


.banner {
  background-color: var(--color-accent);
  border-radius: 16px;
  padding: 30px;
  display: grid;
  grid-template-columns: 65% 35%;
  align-items: center;
}

.banner p {
  font-weight: 700;
  font-size: 68px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.banner button {
  margin-top: 65px;
}

@media (max-width: 1279px) {
  .time {
    font-size: 24px;
  }

  .banner p {
    font-size: 40px;
  }
}
@media (max-width: 992px) {
  .buttonPdf {
    gap: 8px;
  }

  .buttonPdf span {
    min-width: 80px;
  }
}

@media (max-width: 768px) {
  .time {
    font-size: 16px;
  }

  .banner p {
    font-size: 20px;
  }

  .banner button {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 20px;
  }
}


.divTable {
  display: flex;
  justify-content: center;
  gap: 10px;
  overflow: auto;
  padding-bottom: 10px;
}

@media (max-width: 1430px) {
  .divTable {
    flex-direction: column;
  }
}

.row {
  display: flex;
}

.editingInput {
  flex: 0 0 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 28px;
  height: 28px;
  background-color: rgba(0, 0, 0, 0.02);
  border-top: 0;
  border-bottom: 1px;
  border-left: 0;
  border-right: 1px;
  border-color: black;
  border-style: solid;

  box-sizing: border-box;
  font-size: 12px;
}

.row.rowFirst>.editingInput {
  border-top: 1px solid;
}


.editingInput:first-child {
  background-color: rgba(0, 0, 0, 0.05);
  border-left: 1px solid;
  font-weight: 700;
}

.textCenter {
  display: flex;
  justify-content: center;
}

.uploadFile {
  margin-top: 30px;
  max-width: 800px;
  width: 100%;
}

@media (max-width: 1460px) {
  /* .imageContainer a {
    margin: 0 auto;
    height: 440px;
    width: 663px;
  } */
}

@media (max-width: 1279px) {
  .headerContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .pageContainer {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .headerContainer {
    gap: 10px;
    margin-bottom: 10px;
  }

  .title {
    margin-top: 50px;
    font-size: 40px;
  }
}


.card {
  padding: 30px;
  border-radius: 16px;
  background-color: var(--color-blue-gray);
  margin-bottom: 15px;
}

.cardTitle {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray);
  margin-bottom: 15px;
}

.cardList {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

@media (max-width: 768px) {
  .card {
    padding: 10px;
  }
}
.imageContainerNewDesign{
  margin-top: 2.5rem;
}