.wrapper {
  padding: 20px 20px 20px 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--width-sidebar);
  z-index: 100;
  background: var(--color-white);
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin-right: -20px;
}

.wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

@media (min-width: 2060px) {
  .wrapper {
    position: absolute;
    padding-left: 30px;
    margin-left: -30px;
    bottom: auto;
  }
}

.logoWrapper {
  padding: 0 10px;
  margin: 0 auto 27px auto;
  width: 200px;
  height: 60px;
}

.logoWrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.userInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 45px;
}

.avatarWrapper {
  margin-bottom: 15px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 157px;
  background: linear-gradient(134.01deg, var(--color-accent) 38.91%, var(--color-accent-first) 94.5%);
  border-radius: 50%;
}

.avatarWrapper::before {
  position: absolute;
  content: '';
  width: 147px;
  height: 147px;
  background-color: var(--color-dark);
  border-radius: 50%;
}

.avatar {
  position: absolute;
  width: 80%;
  height: 80%;
  object-fit: contain;
  border-radius: 50%;
}

.linkEdit {
  position: absolute;
  right: 23px;
  bottom: 23px;
  width: 30px;
  height: 30px;
}

.btnEdit {
  width: 30px;
  height: 30px;
}

.avatarIco {
  width: 12px;
  height: 12px;
}

.userName {
  margin-bottom: 13px;
  color: var(--color-dark);
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}

.userMail {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navList {
  margin-bottom: 24px;
}

.navLink {
  display: flex;
  align-items: center;
  padding: 16px 10px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray);
}

.navLink.active {
  background-color: var(--color-accent);
  color: var(--color-white);
  padding-left: 22px;
  margin-left: -12px;
}

.navIco {
  margin-right: 15px;
  width: 24px;
  height: 24px;
}

.notice {
  margin-left: 10px;
}


.rating {
  width: calc(100% + 20px);
  color: var(--color-black);
  background-color: var(--color-white);
  box-shadow: 0px 4px 16px rgba(0 0 0 / 0.25);
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  padding-left: 16px;
  margin-left: -20px;
}

.rating svg {
  margin-right: 10px;
}



.balance {
  display: block;
  z-index: 1;
  background-color: #e6ecf2;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-black);
  padding: 8px 5px;
  text-align: center;
  margin-left: -16px;
}

.balance span {
  margin-left: 10px;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-gray);
}

.ratingLink {
  display: inline-block;
  padding: 18px 5px;
  cursor: pointer;
}

.close {
  display: none;
  width: 12px;
  height: 12px;
}

.bg {
  position: fixed;
  inset: 0;
  z-index: 2;
}

@media (max-width: 992px) {
  .wrapper {
    position: fixed;
    padding: 10px 0 0;
    box-shadow: var(--box-shadow);
    border-radius: 0 15px 15px 0;
    transform: translateX(-120%);
    transition: transform 0.5s;
    height: calc(100% - 66px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .wrapper.show {
    transform: translateX(0);
  }

  .close {
    top: 15px;
    right: 10px;
    display: block;
  }

  .logoWrapper {
    padding: 0 60px;
    margin-bottom: 25px;
  }

  .userInfo {
    display: none;
  }

  .navList {
    margin-bottom: 15px;
  }

  .navLink {
    padding: 10px 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .navIco {
    margin-right: 10px;
    width: 15px;
    height: 15px;
  }

  .rating {
    margin-top: auto;
    box-shadow: none;
    border-radius: 0;
  }

  .ratingLink {
    display: none;
  }
}
