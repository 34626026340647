.wrapper {
  width: calc(100% - 60px);
  padding: 16px 24px;
  justify-content: flex-end;
  gap: 20px;
  grid-area: 1 / 2 / 2 / 4;
  background-color:#fff;
  border: 1px solid #F4F4F4;
  border-radius: 12px;
  margin-top: 16px;
  position: relative;
  margin-bottom: 24px;
  align-items:center;
}

.mainLogo {
  max-width: 128px;
}

.support {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  padding-top: 12px;
  border-top: 1px solid var(--color-light-stroke);
}

.support svg {
  width: 24px;
  height: 24px;
}

.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;

}
.buttonLanguage{
  width: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}


.close {
  position: absolute;
  right: 12px;
  top: 12px;
}

/* .button:hover,
.button:focus {
  outline: none;
  box-shadow: 0 4px 16px var(--color-accent);
} */
.numberOfNotifications{
  width: 14px;
  height: 14px;
  display: flex;
  align-items:center;
  justify-content: center;
  background-color:#AB48E0;
  position: absolute;
  top:-2px;
  border-radius:50%;
  right: 0px;
  font-size: 9px;
  color:#fff;
  font-weight: 600;
  font-family: 'Inter';
}

.button span {
  position: absolute;
  top: -5px;
  right: -13px;
}

.avatar img {
  object-fit: cover;
  border-radius: 50%;
  width: 24px;
  height: 24px;
}

@media (max-width: 1280px) {
  .wrapper {
    padding: 20px;
    gap: 15px;
  }
}

@media (max-width: 992px) {
  .wrapper {
    border-radius: 12px;
    padding: 16px 24px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-template-rows: repeat(1, 1fr);

    grid-row-start: 1;
    grid-column-start: 1;
    border: 1px solid var(--color-light-stroke);
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .burger {
    width: 32px;
    height: 32px;
    margin-right: -5px;
  }

  .drawerHeader {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }

  .fio {
    line-height: 1.35;
  }

  .support {
    font-size: 10px;
    border-top: none;
  }

  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
    flex-shrink: 0;
  }

  .avatar img {
    width: 24px;
    height: 24px;
  }
}

/* @media (max-width: 400px) {
  .wrapper {
    grid-template-columns: 55px 1fr 55px;
  }
} */

.headerWrapperLeft{
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: auto;
  
}
.logoDesktop{
  max-width: 127px;
  flex-shrink:0;
}
.logoDesktop img{
  max-height: 30px;
}
.courseTime{
  display: flex;
  align-items: center;
  padding: 0px 14px;
  height: 32px;
  background: #FBF4FF;
  border-radius:12px;
  margin-left: 32px;
}
.courseTimeIcon{
  margin-right: 8px;
}
.courseTimeTitle{
  font-weight: 600;
  font-size: 14px;
  color:var(--color-text-main); 
}
.courseTimeText{
  color:var(--color-time);
  font-weight: 500;
  font-size: 12px;
  margin-left: 12px;
}
.rating{
  display: flex;
  align-items: center;
  padding: 0px 14px;
  padding-left: 12px;
  height: 32px;
  background: #FBFBFB;
  border-radius:12px;
  margin-left: 32px;
}
.ratingIcon{
  padding-right: 4px;
}
.ratingData{
  font-size: 12px;
  font-weight: 500;
  color:var(--color-text-main); 
}
@media (max-width: 992px) {
  .wrapper {
    width: 100%;
  }
}

.buttonsWrapper {
  display: flex;
  gap: 30px;
}
.loadVideoText{
  font-size: 16px;
}
@media (max-width: 1149px) { 
  .buttonsWrapper {
    gap: 10px;
  }
  .loadVideoText{
    font-size: 14px;
  }
}
@media (max-width: 992px) {
  .buttonsWrapper .button {
    padding: 10px 17px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    border-radius: 10px;
  }
}
@media (max-width: 400px) {
  .buttonsWrapper .button {
    padding: 5px 12px;
    font-size: 11px;
  }
}
.btnVideo {
  gap: 10px;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;

  border: 1px solid #F4F4F4;
  padding: 4px 10px;
  position: relative;
  /* top: -4px; */
  border-radius: 5px;
}
.icoWrap {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-yellow);
}
.ico {
  width: 20px;
  height: 20px;
  fill:#fff;
}

@media (max-width: 1400px) { 
  .loadVideoText {
    font-size: 13px;
  }
  .courseTimeTitle {
      font-weight: 600;
      font-size: 12px;
      color: var(--color-text-main);
  }
  
  .wrapper {
    gap: 10px;
  }
  .rating {
    margin-left: 15px;
  }
  .courseTime {
    margin-left: 15px;
  }
  .loadVideoText{
    display: none;
  }
}

@media (max-width: 1250px) { 
  .rating{
    display: none;
  }
}

.mobIconsHeader{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  margin-left: auto;
  margin-right: 7px;
}

@media (max-width: 992px) { 
  .wrapper {
    gap: 0px;
  }
}
 

/* @media (max-width: 380px) { 
  .mobIconsHeader{
    gap: 7px;
    margin-right: 4px;
  }
  .mobIconsHeader button{
    width: 22px;
  }
  .wrapper {
    padding: 14px 18px;
  }
  .mainLogo{
    max-width: 110px;
  }
} */
@media (max-width: 600px) {
  .wrapper {
    padding: 16px 16px;
  }
  .mainLogo {
      max-height: 34px;
  }
  .mainLogo {
      max-height: 34px;
  }
  .mainLogo img{
      max-height: 32px;
  }
}

@media (max-width: 540px) {
  .close {
    width: 24px;
    height: 24px;
    right: 19px;
    top: 24px;
  }
  .avatar img{
    position: relative;
    top:-5px;
  }
  .close svg{
    width: 24px;
    height: 24px;
  }
  .fio {
    font-weight: 600;
    font-size: 20px;
  }
  .drawerHeader{
    padding-top: 11px;
  }
  .drawerHeader a{
    position: relative;
    top:3px;
  }
  
  .support {
    color: #2B2D3E;
  }
  .avatar img {
      height: 36px;
      width: 36px;
  }
  .avatar {
      height: 36px;
      width: 36px;
  }
  .support {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .support svg{
    margin-left: 6px;
  }
  .support:first-child{
    padding-top: 24px;
    padding-bottom: 12px;
  }
  .support{
    padding-bottom: 12px;
    padding-top: 12px;
  }
  .support svg {
    width: 28px;
    height: 28px;
  }
}

.customId{
  padding-left: 0.5rem;
}