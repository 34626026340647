.ProseMirror>*+* {
  margin-top: 0.75em;
  padding-left: 16px;
}

.ProseMirror ul {
  list-style-type: disc;
  padding-left: 16px;
}


.react-component-with-content {
  background: #FAF594;
  border: 3px solid #0D0D0D;
  border-radius: 0.5rem;
  margin: 1rem 0;
  position: relative;
}


.react-component-with-content .content {
  margin: 2.5rem 1rem 1rem;
  padding: 0.5rem;
  border: 2px dashed #0D0D0D20;
  border-radius: 0.5rem;
}

.react-component .tiptap-math {}

.tiptap-math::after {
  content: attr(hint);
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
}

