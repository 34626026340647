.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}

.file {
  width: 100%;
  padding-right: 30px;
  border-radius: 16px;
  margin-right: 30px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: var(--box-shadow);
}

.btnRemove {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
}

.list {
  display: grid;
  gap: 10px;
}

.row {
  position: relative;
  text-align: center;
}

.btnImg {
  width: 46px;
  height: 46px;
  box-shadow: var(--box-shadow-light);
  border-radius: 50%;
  color: var(--color-gray);
  transition: all 0.3s;
  background-color: var(--color-blue-gray);
}

.btnImg>svg {
  width: 12px;
  height: 12px;
}

.btnImg:hover {
  background-color: var(--color-white);
}

.btnImg:active {
  box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}