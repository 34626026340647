.page {
  margin-right: var(--gap);
}

@media (max-width: 992px) {
  .page {
    display: none;
  }
}

.description {
  display: grid;
  gap: 30px;
  background-color: var(--color-blue-gray);
  border-radius: 26px;
  padding: 30px;
  margin-bottom: 30px;
}

.inputNew {
  width: 510px;
}

.filters {
  display: flex;
  gap: 30px;
  margin-bottom: 30px;
}

.list {
  display: grid;
  gap: 10px;
}

.item {
  position: relative;
  background-color: var(--color-blue-gray);
  border-radius: 16px;
  padding: 30px;
}

.title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 30px;
}

.btnEdit {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}