.courseTime{
  display: flex;
  align-items: center;
  padding: 0px 14px;
  height: 32px;
  background: #FBF4FF;
  border-radius:12px;
  margin-left: 32px;
}
.courseTimeIcon{
  margin-right: 8px;
}
.courseTimeTitle{
  font-weight: 600;
  font-size: 14px;
  color:var(--color-text-main); 
}
.courseTimeText{
  color:var(--color-time);
  font-weight: 500;
  font-size: 12px;
  margin-left: 12px;
}
@media (max-width: 1400px) { 
  .courseTime {
    margin-left: 15px;
  }
  .courseTimeText {
      font-size: 10px;
  }
}
@media (max-width: 992px) { 
  .courseTime {
    margin-left: 0px;
  }
  .courseTimeTitle{
    font-size: 12px;
  }
}
@media (max-width: 992px) { 
  .courseTime {
    width: 100%;
  }
  .courseTime{
    margin-top: 10px;
  }
}