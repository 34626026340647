@property --progress {
  syntax: "<percentage>";
  initial-value: 0%;
  inherits: false;
}

.progressBar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 40px;
  padding: 5px;
  background: linear-gradient(90deg, var(--color-accent) var(--progress), var(--color-blue-gray) 0%);
  color: var(--color-white);
  border-radius: 40px;
  box-shadow: 0 4px 16px rgba(0 0 0 / 0.25);
  transition: --progress 0.2s linear;
}

.progressBar > span {
  font-weight: 600;
  font-size: 16px;
  text-shadow: 0 0 3px #000;
}
