.list {
  max-width: 230px;
  width: 100%;
  display: grid;
  gap: 8px;
  align-items: flex-start;
  margin-left: auto;
}

.capsule {
  display: grid;
  grid-template-columns: 50% 50%;
  text-align: center;
}

.capsuleLeft, .capsuleRight {
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.capsuleLeft {
  position: relative;
  z-index: 2;
  background-color: var(--color-blue-gray);
  font-weight: 600;
  border-radius: 16px 0 0 16px;
}

.capsuleRight {
  position: relative;
  z-index: 1;
  background-color:white;
  font-weight: 500;
  border-radius: 0 16px 16px 0;
}

.i {
  position: absolute;
  right: 15px;
  top: 2px;
}

.capsuleRight[data-hint]::after{
  display: flex;
  align-items: center;
  justify-content: center;
  content: attr(data-hint);
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  width: 210px;
  min-height: 28px;
  z-index: 5;
  background-color: white;
  box-shadow: var(--box-shadow);
  padding: 5px 10px;
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  font-size: 11px;
}

.capsuleRight[data-hint]:hover::after {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 768px) {
  .list {
    max-width: 180px;
    position: absolute;
    right: 10px;
    grid-row: 1/1;
    gap: 2px;
  }

  .capsuleLeft, .capsuleRight {
    box-shadow: 0 0 4px 0 rgba(0 0 0 / 20%);
    min-height: auto;
  }

  .capsuleRight {
    padding: 2px 5px;
    display: inline-block;
    word-wrap: break-word;
  }

  .i {
    right: 10px;
    top: 0;
  }

  .capsuleRight[data-hint]:focus:after {
    opacity: 1;
    visibility: visible;
  }

  .capsule:not(:last-child) {
    display: none;
  }

}
