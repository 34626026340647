.title {
  margin-bottom: 25px;
}

.modalBody {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modalMessage {
  margin-left: 15px;
}

@media (max-width: 1024px) {
  .card {
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 18px;
  }
}
