.tooltip{
    position: relative;
    cursor: pointer;
}
.tooltipText{
    position: absolute;
    top: 0px;
    left: 0px;
    background: #fff;
    box-shadow: var(--box-shadow-light);
    color: #404043;
    border-radius: 16px;
    line-height: 1;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: -1;
    opacity: 0;
    font-size: 14px;
    transition: all 0.3s;
}
.tooltip:hover .tooltipText{
    z-index: 4;
    opacity: 1;
}
.tooltip svg{
    width: 1rem;
    height: auto;
}
.tooltipTextDown{
    top: 33px;
}
@media(max-width:1280px){
    .tooltip svg{
        width: 1.3rem;
    }
}
@media(max-width:992px){
    .tooltip svg{
        width: 1.6rem;
    }
}
@media(max-width:767px){
    @media(max-width:992px){
        .tooltip svg{
            width: 3.3rem;
        }
    }
    .tooltipText{
        left: auto !important;
        right: -3px !important;
    }
}
@media(min-width:1981px){
    .tooltipTextDown{
        top: 43px;
    }
}

@media(max-width:400px){
    .tooltipTextDown{
        top: 25px;
    }
}
