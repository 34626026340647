@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Thin'), local('Montserrat-Thin'), url('Montserrat-Thin.woff2') format('woff2');
    font-weight: 100;
    font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'), url('Montserrat-ExtraLight.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Light'), local('Montserrat-Light'), url('Montserrat-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat-Regular'), url('Montserrat-Regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('Montserrat-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('Montserrat-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url('Montserrat-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Black'), local('Montserrat-Black'), url('Montserrat-Black.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url('Montserrat-ExtraBold.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}
