.body {
  position: fixed;
  z-index: 200;
  background-color: var(--color-white);
  inset: 0;
  overflow-y: auto;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 40px;
  gap: 80px;
  align-items: start;
}


@media (max-width: 1024px) {
  .wrapper {
    grid-template-columns: auto;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 0;
  }

}
