.card {
  max-width: 660px;
  width: 100%;
}

.title {
  margin-bottom: 25px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.buttons button {
  height: auto;
}

.buttonLogin {
  width: 100%;
  background-color: #EAEAEA;
}

.buttonLoginVk {
  width: 100%;
  background-color: #CDE0F2;
}

.buttonLoginVk>span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.buttonLoginVkIco {
  width: 36px;
  height: 36px;
}

@media (max-width: 1200px) {
  .buttons {
    grid-template-columns: auto;
  }
}

@media (max-width: 1024px) {
  .card {
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .buttons {
    grid-template-columns: repeat(2, 1fr);
  }

  .buttonLoginVkIco {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 18px;
  }

  .bottomLinks {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons {
    grid-template-columns: auto;
  }
}
.disabledStyle div span::after{
  top:44% !important;
}
.agreement{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.agreement div span{
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}
.modal{
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  justify-content: center;
  position: fixed;
  background: rgb(36, 31, 31, 0.66);
  position: fixed;
  top:0;
  left: 0;
  z-index: 3333;
}
.modalContent{
  margin: auto;
}
.icoRemove{
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  width: 44px;
  height: fit-content;
}
.icoRemove path{
  fill:#fff;
}
.buttonLoginVk:disabled span span{
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
@media (max-width: 600px) {
  .icoRemove{
    right: 10px;
    top: 10px;
    width: 34px;
  }
}
@media (max-width: 520px) {
  .icoRemove{
    right: 3px;
    top: 3px;
    width: 24px;
  }
  .icoRemove path{
    fill:#000;
  }
  .modal{
    background: rgb(36, 31, 31, 0.46)
  }
}