.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
 }
.list {
  display: grid;
  gap: 10px;
  max-width: 600px;
}
.row {
  position: relative;
  text-align: center;
}