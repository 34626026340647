/*Компоненты*/
.antInputWidth {
  max-width: 250px;
  width: 100%;
}

.ant-input {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}


.ant-input-affix-wrapper {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}

.ant-input-affix-wrapper .ant-input {
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker-focused,
.ant-picker:hover,
.ant-input-focused,
.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-btn:focus,
.ant-btn:hover {
  border-color: var(--color-accent);
}

.ant-picker {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}

.rc-virtual-list-scrollbar-thumb {
  background-color: var(--color-accent) !important;
}

.rc-virtual-list-scrollbar {
  right: 8px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-accent);
  color: white;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  display: flex;
  align-items: center;
}




/* заполнение аватарки */
.ant-image {
  height: inherit;
}

.ant-image .ant-image-img {
  height: 100%;
  object-fit: cover;
}


/* Кнопки */
.ant-btn.ant-btn-default:not(:disabled) {
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}

button.ant-btn-primary.ant-btn-dangerous {
  background-color: var(--color-accent-first);
}



.ant-btn-primary {
  background-color: var(--color-yellow);
  border-color: transparent;
}


.ant-btn-primary:hover {
  background-color: var(--color-accent);
}

.ant-btn-primary:focus:not(.ant-btn-dangerous) {
  background-color: var(--color-yellow);
  border-color: var(--color-accent);
}

/* radio */
.ant-radio-inner {
  border-radius: 50%;
  overflow: hidden;
}

.ant-radio-inner:after {
  background-color: var(--color-accent);
}

.ant-radio-checked .ant-radio-inner {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
}

.ant-radio-checked .ant-radio-inner:after {
  transform: scale(0);
}

.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
  border-color: var(--color-accent);
}

/* группа */
.ant-radio-button-wrapper {
  display: inline-flex;
  align-items: center;
}

/* Модальное окно */
.ant-modal-content {
  border-radius: 20px;
  background-color: #F6F6F6;
}

/* таблица с бублами */
.antd_table-booble {
  overflow: hidden;
  padding-bottom: 8px;
}

.antd_table-booble .ant-table,
.antd_table-booble .ant-table-thead>tr>th,
.antd_table-booble .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background-color: var(--color-blue-gray);
}

.antd_table-booble .ant-table-content::-webkit-scrollbar-track,
.antd_table-booble .ant-table-body::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: var(--color-blue-gray);
}

.antd_table-booble .ant-table-content::-webkit-scrollbar,
.antd_table-booble .ant-table-body::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.ant-table-wrapper .ant-table-tbody>tr:last-child>td:first-child {
  border-radius: 0 0 var(--ant-border-radius-16) 0;
}

.ant-table-wrapper .ant-table-tbody>tr:last-child>td:last-child {
  border-radius: 0 var(--ant-border-radius-16) 0 0;
}

/* таблица с журналом */
.antd_table-journal .ant-table-cell {
  font-size: 12px;
}

.antd_table-journal .ant-table-content::-webkit-scrollbar-track {
  border-radius: 7px;
  background-color: var(--color-blue-gray);
}

.antd_table-journal .ant-table-content::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.ant-table-content::-webkit-scrollbar-thumb,
.antd_table-booble .ant-table-body::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: var(--color-accent);
}


.antd_table-booble.ant-table-wrapper table {
  border-collapse: collapse;
}

.antd_table-journal .ant-table-container table>thead>tr:first-child th:first-child {
  border-start-start-radius: 0;
}

.antd_table-journal .ant-table-container table>thead>tr:first-child th:last-child {
  border-start-end-radius: 0;
}


.antd_table-journal td.ant-table-column-sort.ant-table-cell-fix-left {
  background-color: white;
}

.antd_table-journal .ant-table-thead th.ant-table-column-sort {
  background-color: #eee;
}

.antd_table-journal .ant-table-thead>tr>th {
  background-color: #eee;
  border-bottom: 1px solid #bbb;
  border-top: 1px solid #bbb;
}

.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td,
.antd_table-journal .antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.antd_table-journal .ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
  border-right: 1px solid #bbb;
}

.antd_table-journal .ant-table-tbody>tr>td {
  border-bottom: 1px solid #bbb;
}

.antd_table-journal .ant-table-thead>tr>th:first-child,
.antd_table-journal .ant-table-tbody>tr>td:first-child {
  border-left: 1px solid #bbb;
}

.antd_table-journal .ant-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  margin: 0;
  border-radius: 0;
}

.antd_table-journal {
  margin-bottom: 30px;
}

@media (max-width: 992px) {
  .antd_table-journal {
    padding-bottom: 80px;
  }
}


/* календарь */

.ant-picker-dropdown .ant-picker-content th {
  height: auto;
}

.ant-picker-dropdown .ant-picker-content th,
.ant-picker-dropdown .ant-picker-content td {
  min-width: auto;
}

.ant-picker-dropdown .ant-picker-header button,
.ant-picker-dropdown .ant-picker-header-view,
.ant-picker-dropdown .ant-picker-footer {
  line-height: 3em;
}

.ant-picker-dropdown .ant-picker-decade-panel,
.ant-picker-dropdown .ant-picker-year-panel,
.ant-picker-dropdown .ant-picker-quarter-panel,
.ant-picker-dropdown .ant-picker-month-panel,
.ant-picker-dropdown .ant-picker-week-panel,
.ant-picker-dropdown .ant-picker-date-panel,
.ant-picker-dropdown .ant-picker-time-panel {
  width: 280px;
}

.ant-picker-dropdown .ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell-inner {
  width: 100%;
}

.ant-picker-dropdown .ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-picker-dropdown .ant-picker-decade-panel .ant-picker-content,
.ant-picker-dropdown .ant-picker-year-panel .ant-picker-content,
.ant-picker-dropdown .ant-picker-quarter-panel .ant-picker-content,
.ant-picker-dropdown .ant-picker-month-panel .ant-picker-content {
  height: 300px;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content th {
  width: auto;
}

.ant-picker-dropdown .ant-picker-date-panel .ant-picker-content {
  width: 252px;
}

.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  height: 30px;
  min-width: 30px;
  line-height: 30px
}


.ant-table-wrapper .ant-table-summary {
  background-color: var(--color-accent);
  color: #fff;
  border-radius: 0 0 16px 16px;
  font-size: 20px;
  padding-left: 10px;
}

.ant-table-summary .ant-table-cell {
  font-weight: 600;
}

.stuleSelektKod .ant-input-group-addon{
  overflow: hidden;
  box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
}
.stuleSelektKod .ant-select, .stuleSelektKod .ant-select-selector{
  border: none !important;
}
.stuleSelektKod .ant-select-disabled{
  background:rgba(0, 0, 0, 0.04) !important;
}

.newStyleTable th, .newStyleTable td{
  text-align: center !important;
}
.newStyleTable th span, .newStyleTable td span{
  line-height: 1.3 !important;
}
@media (max-width: 767px) {
  .newStyleTable th, .newStyleTable td{
    padding: 8px !important;
  }
  .newStyleTable .ant-table-measure-row td{
    padding: 0px !important;
  }
  /* .newStyleTable th span, .newStyleTable td span{
    font-size: 13px;
  } */
}
.newStyleTable th::before{
  display: none;
}
.newStyleTable .ant-table, .newStyleTable .ant-table-cell{
  background:none !important;
}
.newStyleTable .ant-table-body{
  overflow: auto !important;
}
.newStyleTable tr:last-child td{
  border-bottom: 0px solid #fff !important;
}
.newStyleTable{
  border: 1px solid #e2d4e9;
  border-radius: 0.5rem;
}
.newStyleTable .backgroundNone{
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.newStyleTable .ant-table-content::-webkit-scrollbar, .newStyleTable .ant-table-body::-webkit-scrollbar{
  height: 3px !important;
  width: 3px !important;
  background: #ab48e0 !important;
} 
.antdSelectNewDesign{
  display: flex;
  align-items: center;
  height: 2.4rem;
}
.antdSelectNewDesign *::after{
  display: none !important;
}
body .antdSelectNewDesign .ant-select-selector{
  height: 2.4rem !important;
  box-shadow: none !important;
  border-radius: 0.5rem;
  border: 1px solid #e2d4e9 !important;
  background: #f0e6f6 !important;
}
.antdSelectNewDesign .ant-select-selection-placeholder{
  line-height: initial !important;
}
.antdSelectNewDesign input{
  height: fit-content !important;
}
.antdSelectNewDesign *, .antdSelectNewDesign .ant-select-selection-placeholder, .antdSelectNewDesign .ant-select-selection-item{
  font-size: 0.875rem !important;
  font-family: 'Montserrat' !important;
  font-weight: 400 !important;
  color: #2B2D3E !important;
}
.antdSelectNewDesign.ant-select-open .ant-select-suffix{
  transform: rotate3d(1, 0, 0, 180deg)
}
@media (max-width: 1280px) {
  .antdSelectNewDesign{
    height: calc(2.4rem * 1.3) !important;
  }
  body .antdSelectNewDesign .ant-select-selector{
    height: calc(2.4rem * 1.3) !important;
    border-radius: calc(0.5rem * 1.3);
  }
  .antdSelectNewDesign *, .antdSelectNewDesign .ant-select-selection-placeholder, .antdSelectNewDesign .ant-select-selection-item{
    font-size: calc(0.875rem * 1.3) !important;
  }
}
@media (max-width: 992px) {
  .antdSelectNewDesign{
    height: calc(2.4rem * 1.6) !important;
  }
  body .antdSelectNewDesign .ant-select-selector{
    height: calc(2.4rem * 1.6) !important;
    border-radius: calc(0.5rem * 1.6);
  }
  .antdSelectNewDesign *, .antdSelectNewDesign .ant-select-selection-placeholder, .antdSelectNewDesign .ant-select-selection-item{
    font-size: calc(0.875rem * 1.6) !important;
  }
}
@media (max-width: 767px) {
  .antdSelectNewDesign{
    height: calc(2.4rem * 3.3) !important;
  }
  body .antdSelectNewDesign .ant-select-selector{
    height: calc(2.4rem * 3.3) !important;
    border-radius: calc(0.5rem * 3.3);
  }
  .antdSelectNewDesign *, .antdSelectNewDesign .ant-select-selection-placeholder, .antdSelectNewDesign .ant-select-selection-item{
    font-size: calc(0.875rem * 2.88) !important;
  }
  .antdSelectNewDesignFlexAutoMob {
    flex: auto !important;
    width: 100%;
  }
}
@media (max-width: 567px) {
  .antdSelectNewDesign *, .antdSelectNewDesign .ant-select-selection-placeholder, .antdSelectNewDesign .ant-select-selection-item{
    font-size: calc(0.875rem * 3.1) !important;
  }
}
.dropdown.ant-dropdown-open .ant-space-item svg{
  transform: rotate(180deg);
}
.dropdown.ant-dropdown-trigger .ant-space-item svg{
  transition: all 0.3s;
}
.dropdown.ant-dropdown-trigger{
  cursor: pointer;
}
.dropdown.ant-dropdown-trigger .ant-space-item{
  white-space: nowrap;
}