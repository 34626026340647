.wrapper {
  min-width: 235px;
  box-shadow: var(--box-shadow);
  background-color: var(--color-white);
  border-radius: 16px;
  padding: 15px;
  font-weight: 700;
  font-size: 14px;
  color: var(--color-black);
  user-select: none;
  min-height: 215px;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
}

.btn:hover {
  box-shadow: var(--box-shadow);
}


.btn:active {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.btnIco {
  height: 1em;
  width: 1em;
}

.heed {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.headMode {
  font-weight: 700;
}

.headModeSelect {
  cursor: pointer;
  border-radius: 5px;
}

.headModeSelect:hover {
  color: var(--color-accent);
}

.body {
  display: grid;
}

.modeDays {
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.modeMonths {
  grid-template-columns: repeat(4, 1fr);
  gap: 5px;
}

.calendarWeekDay {
  color: var(--color-gray);
  text-align: center;
}

.calendarBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 3px rgba(0 0 0 / 0.2);
  text-align: center;

  font-weight: 600;
  border: 1px solid transparent;
}

.modeDays .calendarBtn {
  border-radius: 4px;
  height: 25px;
  width: 25px;
}

.modeMonths .calendarBtn {
  border-radius: 6px;
  height: 47.5px;
  width: 47.5px;
}

.calendarBtn:not(.dayAdditional):hover,
.calendarBtn:not(.dayAdditional):focus-visible {
  outline: none;
  border-color: var(--color-accent);
}

.calendarBtn:not(.dayAdditional):active,
.calendarBtn:not(.dayAdditional).active {
  border-color: var(--color-accent);
  background-color: var(--color-accent);
  color: var(--color-white);
}

.dayAdditional {
  color: var(--color-gray);
  cursor: default;
}

.today {
  background-color: var(--color-yellow);
}
