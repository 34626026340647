.balanceWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 100%;
}

.balanceBlock {
    margin-top: auto;
    height: 96px;
    background-image: url("../../../images/balance-background.svg");
    color: var(--color-black);
    border-radius: 16px;
    text-align: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid var(--color-light-stroke);
}

.balance {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    font-size: 12px;
    color: #C5C6D9;
}

.balanceFooter {
    display: flex;
    gap: 24px;
    font-size: 14px;
    line-height: 1.1;
 }

.money {
    color: var(--color-text-main);
    font-size: 24px;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 8px;
}

@media (max-width: 992px) {

    .balanceBlock {
        height: 58px;
        background-position-y: 40%;
    }

    .balance {
        font-size: 10px;
        flex-direction: row;
        gap: 32px;
    }

    .balanceIcon {
        width: 34px;
        height: 34px;
    }

    .balanceIcon svg {
        width: 100%;
        height: 100%;
    }

    .balanceFooter {
        flex-direction: column;
        font-size: 10px;
        gap: 4px;
        line-height: 1.6;
    }

    .money {
        font-size: 16px;
    }
}