.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
 }
.list {
  display: grid;
  gap: 10px;
  max-width: 600px;
}
.row {
  position: relative;
  text-align: center;
}

.scheme{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  max-width: calc(100% - 45px);
}
.heading{
  margin-bottom: -3px;
}
.schemeItem{
  display: flex;
  gap: 10px;
  max-width: 700px;
  position: relative;
}
.fileWrapper{
  width: calc(50% - 5px);
}
.selectWrapper{
  width: calc(50% - 5px);
}
.file svg{
  width: 17px;
  height: 17px;
}
.file label{
  line-height: 1;
}