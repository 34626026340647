* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

*,
*:focus-within,
*:focus-visible,
*:focus,
*:active {
  outline: none !important;
  outline-width: 0 !important;
  outline-color: transparent !important;
}

body {
  margin: 0;
  color: inherit;
  font-size: 16px;
  font-variant: inherit;
  line-height: inherit;
  background-color: #fff;
  font-feature-settings: inherit;
  font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}

.pointerCursor {
  cursor: pointer !important;
}


.new_card {
  background-color: #E6ECF2;
  border-radius: 16px;
  padding: 30px;
}

@media (max-width: 768px) {
  .new_card {
    padding: 30px 15px;
  }
}


.question-form .swiper-wrapper {
  z-index: unset;
}