:root {
  /* colors */
  --color-accent: #6e368c;
  --color-accent_rgb: 110, 54, 140;

  --color-accent-first: #ed6b69;
  --color-accent-second: #287abe;

  --color-blue-gray: #e6ecf2;
  --color-gray: #a3a3a5;
  --text-active: #11142D;

  --color-white: #fff;
 

  --color-black: #000;
  --color-dark: #3a3938;
  --color-smoke: #f6f6f6;
  --color-yellow: #fcc246;
  --color-green: #138e1f;

  /*  */
  --box-shadow: 0 4px 16px rgba(0 0 0 / 0.25);
  --box-shadow-light: 0 4px 4px rgba(0 0 0 / 0.25);

  /*  */
  --width-sidebar: 270px;
  --width-page-menu: 500px;
  --gap: 40px;

  --booble-height: 55px;

  --ant-border-radius-16: 16px;
  --ant-border-radius-26: 26px;

  /* цвета нового дизайна*/

  --color-light-grey-nd: #FAFAFB;
  --color-mid-grey-nd: #FBFBFB;
  --color-grey-nd: #F7F7F7;

  --color-light-stroke: #F4F4F4;
  --color-stroke-hover: #EDE0FF;
  --color-light-btn: #FBF4FF;
  --color-accent-btn: #F6E5FF;

  --color-text-main: #2B2D3E;
  --color-text-secondary: #423B46;
  --color-text-lavel-status: #AAABC0;
  --color-text-lavel: #2B3674;
  --color-violet: #ae9be4;
  --color-violet-dark: #9A82DD;
  --color-time:#B59FC2;
  --color-red:#EE7A45;
  --padding-right-new-design: 60px;

  --color-green-deep: #4BB042;
  --color-yellow-deep: #FECA06;
  --color-blue-deep: #0CC1F3;
  --color-pink-deep: #EE347D;

}


@media (max-width: 1439px) {
  :root {
    --width-sidebar: 270px;
    --width-page-menu: 400px;
    --gap: 20px;
  }
}

@media (max-width: 992px) {
  :root {
    --width-sidebar: 240px;
    --padding-right-new-design: 0px;
  }
}

@media (max-width: 992px) {
  :root {
    --ant-border-radius-16: 12px;
    --ant-border-radius-26: 16px;
  }
}
