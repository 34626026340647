.confirm {

}

.confirm .title {
  font-weight: bold;
  margin-bottom: 30px;
}

.confirm .buttonRow {
  margin-top: 30px;
}

.confirm .buttonRow > button {
  padding-left: 30px;
  padding-right: 30px;
}
