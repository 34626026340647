.wrapper {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.input {
  width: 100%;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 30px;
}

.list {
  display: grid;
  gap: 10px;
  max-width: 600px;
}

.row {
  display: grid;
  grid-template-columns: 42px 1fr;
  align-items: center;
  gap: 10px;
  position: relative;
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 8px;
  width: 42px;
  height: 38px;
  border: 1px solid #e2dfdf;
  font-weight: 500;
  font-size: 14px;
}