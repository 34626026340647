.wrapper {
  border: 1px solid #bebebe;
  background: #FCFCFC;
  padding: calc(1.43 * 1rem);
  border-radius: 16px;
  display: flex;
  gap: 15px;
}

.verticalDivider{
  width: calc(0.25 * 1rem);
  background: #F2EDF5;
  border-radius: calc(0.25 * 1rem);
}

.title {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0px;
}

.headStudent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 10px;
}

.headerStudentPercent {
  white-space: nowrap;
}

.rightSideCard{
  flex-grow: 1;
  width: 100%;
}

.leftSideCard{
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: calc(1rem * 11.825);
}

.headerTeacher {
  position: relative;
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  column-gap: 30px;
  row-gap: 10px;
  margin-bottom: 30px;
}

.headerTeacherDate {
  max-width: 530px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
}

.headerTeacherBooble {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
}

.headerTeacherBooble span {
  margin-left: 5px;
  font-weight: inherit;

}

.headStudentPercent {
  position: absolute;
  top: 15px;
  right: 0;
  white-space: nowrap;
}

.nameWrap {
  max-width: 380px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-right: auto;
}

.nameWrap .title {
  box-orient: vertical;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  display: -moz-box;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  /* line-clamp: 2; */
}

.ava {
  flex: 0 0 55px;
  height: 55px;
}

.rightBtn {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.subTitleHeader {
  padding: 0px 20px;
  max-width: fit-content;
  min-width: 1ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
}

.namesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.subtitleText {
  font-size: 14px;
}

@media (max-width: 1100px) {
  .nameWrap {
    max-width: 100%;
    margin-right: 0;
  }

  .headerTeacher {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding: 10px;
  }

  .title {
    font-weight: 500;
    font-size: calc(1rem * 3.3);
  }

  .headerTeacherDate {
    max-width: 100%;
    gap: 10px;
  }

  .headStudentPercent {
    top: 10px;
  }

  .headerTeacherBooble {
    line-height: 14px;
    height: 35px;
    padding: 2px 10px;
  }

  .headStudent,
  .headerTeacher {
    margin-bottom: 10px;
  }

  .ava {
    flex: 0 0 40px;
    height: 40px;
  }

  .rightBtn{
    flex-direction: column;
  }
}





.head {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-end;
  column-gap: calc(1rem * 0.6);
  row-gap: 10px;
  margin-bottom: calc(1rem * 0.7);
}

.valueBox {
  display: grid;
  gap: 5px;
}

.valueBoxTitle {
  /* color: var(--color-gray); */
  font-weight: 500;
  font-size: calc(1rem * 0.5);
  padding-left: calc(1rem * 0.4);
}

.valueBoxBooble {
  display: flex;
  align-items: center;
  min-height: 53px;
  padding: 5px 25px;
  font-size: 12px;
  overflow: hidden;
}

.headBalls {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  padding: 5px;
  background-color: var(--color-yellow);
}

.taskList {
  margin-bottom: 1rem;
}

.action {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.actionBooble {
  background: #F9F9F9;
  border: 1px solid #9683D742;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 53px; */
  padding: calc(1rem * 0.625) calc(1rem * 0.468);
  border-radius: calc(1rem * 0.5);
  box-shadow: none;
  font-weight: 500;
  text-align: center;
}



@media (max-width: 1280px) {
  .head {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .head {
    gap: 10px;
    margin-bottom: 10px;
  }

  .valueBoxBooble,
  .headBalls,
  .actionBooble {
    min-height: 35px;
    line-height: 120%;
    padding: 2px 10px;
    justify-content: center;
    text-align: center;
  }

  .taskList {
    margin-bottom: 10px;
  }

  .action {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

.abstract {
  display: grid;
  gap: 15px;
  margin-bottom: 30px;
}

.textarea {
  max-width: 475px;
  width: 100%;
}

body .buttonInfo{
  background: #F9F9F9 !important;
  border: 1px solid #9683D742;
}

body .buttonInfoBigPaddings{
  padding-top: 0.312rem;
  padding-bottom: 0.312rem;
  min-height: calc(1rem * 2.75);
  flex-direction: column;
}

@media (max-width: 1280px) {
  body .buttonInfoBigPaddings{
    padding-top: calc(0.312rem * 1.3);
    padding-bottom: calc(0.312rem * 1.3);
  }
}

@media (max-width: 992px) {
  body .buttonInfoBigPaddings{
    padding-top: calc(0.312rem * 1.6);
    padding-bottom: calc(0.312rem * 1.6);
  }
}

@media (max-width: 767px) {
  body .buttonInfoBigPaddings{
    padding-top: calc(0.312rem * 3.3);
    padding-bottom: calc(0.312rem * 3.3);
  }
}

body .buttonActionRounded{
  background: #F9EDFF;
  border: 1px solid #9683D742;
  border-radius: 1rem;
  text-align: center;
}
.buttonActionRoundedLink{
  display: flex;
  height: fit-content;
  width: fit-content;
}
body .buttonActionAlert{
  background: #EE7A45 !important;
  border: none;
}

.gravityRight{
  justify-content: flex-start;
}

.headerInformationBlock{
  right: 0;
  white-space: nowrap;
  position: relative;
  display: flex;
  flex-direction: column;
}

.headerInformationBlockText{
  text-align: end;
  font-weight: 600;
  font-size: calc(1rem * 0.75);
  color: #505050BF;
}

.actionBoobleRed{
  background-color: #F8D4D4;
}

.actionBoobleGreen{
  background-color: #DCCD6A;
}

.actionBoobleSubtitle{
  font-family: Montserrat;
  font-size: calc(1rem * 0.625);
  font-weight: 500;
  line-height: 1rem;
  text-align: center;
}

@media (max-width: 767px) {
  
}

@media (max-width: 1280px) {
  .valueBoxTitle{
    font-size: calc(0.5rem * 1.3);
    padding-left: calc(0.5rem * 1.3);
  }
  .headerInformationBlockText{
    font-size: calc(0.75rem * 1.3);
  }
  .actionBooble{
    font-size: calc(1rem * 1.3);
    padding: calc(0.625rem * 1.3) calc(0.468rem * 1.3);
    border-radius: calc(0.5rem * 1.3);
  }
  .subtitleText {
    font-size: calc(0.875rem * 1.3);
  }
}

@media (max-width: 992px) {
  .valueBoxTitle{
    font-size: calc(0.5rem * 1.6);
    padding-left: calc(0.5rem * 1.6);
  }
  .headerInformationBlockText{
    font-size: calc(0.75rem * 1.6);
  }
  .leftSideCard{
    padding-bottom: calc(0.7rem * 1.6);
  }
  .actionBooble{
    font-size: calc(0.85rem * 1.6);
    padding: calc(0.625rem * 1.6) calc(0.468rem * 1.6);
    border-radius: calc(0.5rem * 1.6);
  }
  .subtitleText {
    font-size: calc(0.875rem * 1.6);
  }
}

@media (max-width: 767px) {
  .valueBoxTitle{
    font-size: calc(0.5rem * 3.3);
    padding-left: calc(0.5rem * 3.3);
  }
  .headerInformationBlockText{
    font-size: calc(0.5rem * 3.3);
  }
  .leftSideCard{
    padding-bottom: calc(0.7rem * 3.3);
  }
  .actionBooble{
    font-size: calc(1rem * 2.3);
    padding: calc(0.625rem * 2.3) calc(0.468rem * 2.3);
    border-radius: calc(0.5rem * 2.3);
  }
  .subtitleText {
    font-size: calc(0.875rem * 3.3);
  }
}