.wpapper{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.optionsWrapper{
  background: #F4F4F4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px 24px;
  gap: 10px;
}
.title{
  color: #2B2D3E;
  font-size: 18px;
  font-weight: 600;
}
.options{
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
}
.optionsBasket{
  gap: 9px;
}
.option{
  background: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  padding: 9px 28px;
  border-radius: 8px;
}
.optionBasket{
  background: #FFFFFF;
  font-size: 12px;
  font-weight: 600;
  padding: 6px 20px;
  border-radius: 8px;
}
.optionGreen{
  background: #C7F3BE;
}
.optionRed{
  background: #ED6B6A;
}
.baskets{
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
}
.basket{
  width: calc(50% - 14px);
  background: #F2EDF5;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 15px;
}
.optionCurrent{
  border: 2px solid rgba(90, 52, 219, 0.9)
}
.optionImg{
  background: none;
  padding: 0px;
  border-radius: 8px;
  overflow: hidden;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.optionImg img{
  width: 100%;
  height: fit-content;
}
.basketOptionImg{
  width: 56px;
}
@media (max-width: 1280px) {
  .wpapper{
    gap: 15px;
  }
  .optionsWrapper{
    padding: 12px 20px;
    gap: 8px;
  }
  .title{
    font-size: 16px;
  }
  .options{
    gap: 14px;
  }
  .optionsBasket{
    gap: 7px;
  }
  .option{
    padding: 6px 20px;
  }
  .optionBasket{
    padding: 6px 15px;
  }
  .baskets{
    gap: 20px;
  }
  .basket{
    width: calc(50% - 10px);
    padding: 15px;
    gap: 10px;
  }
  .optionImg{
    width: 120px;
    padding: 0px;
  }
  .basketOptionImg{
    width: 46px;
  }
}
@media (max-width: 767px) {
  .baskets{
    flex-direction: column;
  }
  .basket{
    width: 100%;
  }
}