.wrapper {
  background-color: var(--color-blue-gray);
  padding: 30px;
  border-radius: 16px;
  margin-bottom: 30px;
  box-shadow: var(--box-shadow-light);
}

.title {
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 20px;
}

.list {
  display: grid;
  gap: 20px;
  margin-bottom: 30px;
}

.buttonsWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.heading {
  font-weight: 500;
  font-size: 14px;
  max-width: 150px;
}

.btnAdd {
  font-weight: 400;
  font-size: 18px;
  background-color: var(--color-blue-gray);
  border-radius: 10px;
  box-shadow: var(--box-shadow-light);
  height: 38px;
  width: 65px
}

.btnAdd:hover {
  background-color: var(--color-white);
}

.btnAdd:active {
  background-color: var(--color-white);
  box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}

div.inputNew {
  width: 100%;
}

@media (max-width: 767px) {
  .buttonsWrap {
    gap: 10px;
  }
}
