.input {
  display: block;
  max-width: 600px;
  width: 100%;
  border: none;
  background: white;
  box-shadow: var(--box-shadow-light);
  border-radius: 24px;
  padding: 16px 32px 15px;
  margin-bottom: 20px;
}

.inputCorrection {
  margin: 0;
}

.answerWrap, .answerWrapTask {
  max-width: 600px;
  display: grid;
  grid-template-columns: 200px auto;
  row-gap: 10px;
}

.answerWrapTask {
  margin-bottom: 20px;
}

.capsuleLeft, .capsuleRight {
  width: auto;
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 150px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.capsuleLeft {
  position: relative;
  z-index: 2;
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
  border-radius: 16px 0 0 16px;
}

.capsuleRight {
  position: relative;
  z-index: 1;
  background-color:white;
  font-weight: 500;
  border-radius: 0 16px 16px 0;
}

.red {
  background-color: var(--color-accent-first);
}

.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}
@media (max-width: 768px) {
  .input {
    margin-bottom: 10px;
  }

  .answerWrap, .answerWrapTask {
    max-width: 200px;
    display: flex;
    flex-direction: column;
    row-gap: 0;
  }

  .capsuleLeft, .capsuleRight {
    width: 100%;
    border-radius: 16px;
    margin-bottom: 5px;
  }

  .capsuleRight {
    margin-bottom: 10px;
  }
}
