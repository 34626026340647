.list {
  display: grid;
  max-width: 600px;
  gap: 5px;
  margin-bottom: 20px;
}

.link {
  display: block;
  position: relative;
}

.link img {
  max-width: 100%;
  max-height: 100%;
}

.title {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .title {
    font-size: 14px;
    margin-bottom: 5px;
  }
  .list {
    margin-bottom: 10px;
  }
}
