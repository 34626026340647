.logoWrap {
  padding: 30px 40px 0 40px;
  margin-bottom: 40px;
}

.logo {
  height: 60px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.logoWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap:15px
}