.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}

.range {
  --colorBg: var(--color-white);
}
