.alerts {
  display: grid;
  gap: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  max-width: 415px;
  width: 100%;
  padding: 0 15px 15px 0;
  justify-items: end;
}

.alert {
  --color: var(--color-accent);

  position: relative;
  display: grid;
  gap: 15px;
  min-height: 70px;
  max-width: 400px;
  width: 100%;
  background-color: var(--color);
  padding: 15px;
  border-radius: 6px;
  animation: SlideLeft 0.3s;
  animation-fill-mode: forwards;
  box-shadow: var(--box-shadow-light);
  overflow: hidden;
  color: white
}

.alert.exit {
  animation: SlideRight 0.3s;
  animation-fill-mode: forwards;
}

.alert.error {
  --color: var(--color-accent-first);
}

.alert.warning {
  --color: var(--color-yellow);
  color: black;
}

.btnClose {
  position: absolute;
  top: 15px;
  right: 15px;
  color: inherit;
}

.btnClose svg {
  color: inherit;
  width: 18px;
  height: 18px;
}

.title {
  padding-right: 18px;
}

.text {
  font-size: 14px;
  /* color: var(--color-gray); */
}

.btnCopy {
  display: inline-block;
  width: auto;
  font-size: 12px;
  padding: 7px 15px;
  border-radius: 6px;
  border: 1px solid var(--color);
  margin-left: auto;
  transition: all, 0.3s;
}

.btnCopy:hover {
  background-color: var(--color);
}

.error .btnCopy:hover {
  color: white;
}

.bar {
  position: absolute;
  bottom: 0;
  height: 2px;
  background-color: var(--color-accent-second);
}

.list {
  display: grid;
  gap: 15px;
  padding-left: 4px;
}

.list li {
  font-size: 14px;
  position: relative;
}

.list li::before {
  content: '-';
  position: absolute;
  left: -10px;
}

.link, .file {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.link:hover {
  text-decoration: underline;
}

@keyframes SlideLeft {
  0% {
    margin-left: 120%;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes SlideRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 120%;
  }
}

@media (max-width: 480px) {
  .alert {
    max-width: 300px;
  }
}
