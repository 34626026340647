.wrapper {
  display: inline-block;
  width: min(235px, 100%);

}

.select {
  width: 100%;
  font-weight: 400;
  padding-right: 20px;
  padding-left: 20px;
  border: 1px solid transparent;
}

.select.error {
  border-color: var(--color-accent-first);
}

.select>span {
  font-weight: inherit;
  user-select: none;
}

.ico {
  border-radius: 5px;
  margin-left: auto;
  width: 24px;
  height: 24px;
}

.ico:hover {
  color: var(--color-accent);
}

.calendarContainer {
  position: absolute;
  margin-top: 10px;
  z-index: 1;
}

.calendarContainer.periodWrapper {
  margin-top: 0;
  top: 0;
  left: 0;
}

.calendarContainer:not(.show) {
  display: none;
}

.input {
  width: 100%;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  background-color: transparent;
  color: inherit;
}

.input::-webkit-calendar-picker-indicator {
  display: none;
}


@media only screen and (max-width: 768px) {
  .ico {
    display: none;
  }
}
.widthMaxNewDesign{
  width: 100%;
}