.page {
    width: 100%;
    background: var(--color-white);
    /*z-index: 10;*/
    height: calc(100vh * 0.796);
    border-radius: 12px;
    border: 1px solid var(--color-light-stroke);
    display: flex;
    flex-direction: column;
    position: relative;
}

.page::before {
    background-color: var(--color-white);
    display: inline-flex;
    padding: 4px;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow-light);
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M3.76024 1.01529C3.9925 0.783025 3.9925 0.406456 3.76024 0.174196C3.52798 -0.0580652 3.15141 -0.0580652 2.91915 0.174196L0.174196 2.91915C-0.0580652 3.15141 -0.0580652 3.52798 0.174196 3.76024L2.91915 6.5052C3.15141 6.73746 3.52798 6.73746 3.76024 6.5052C3.9925 6.27294 3.9925 5.89637 3.76024 5.66411L2.03057 3.93444H11.9694L10.2398 5.66411C10.0075 5.89637 10.0075 6.27294 10.2398 6.5052C10.472 6.73746 10.8486 6.73746 11.0808 6.5052L13.8258 3.76024C14.0581 3.52798 14.0581 3.15141 13.8258 2.91915L11.0808 0.174196C10.8486 -0.0580652 10.472 -0.0580652 10.2398 0.174196C10.0075 0.406456 10.0075 0.783025 10.2398 1.01529L11.9694 2.74496H2.03057L3.76024 1.01529Z' fill='%23AAABC0'/%3E%3C/svg%3E");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: -10px;
    width: 20px;
    height: 20px;
    top: calc(100% / 2 - 20px);
}

.header {
    padding: 18px;
    border-bottom: 1px solid var(--color-light-stroke);
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.participants {
    font-size: 12px;
    color: #32353E80;
}

.dots {
    transition: opacity .3s ease;
    cursor: pointer;
    margin-left: auto;
}

.footer {
    margin-top: auto;
    position: sticky;
    bottom: 0;
}

.footerForm {
    box-shadow: 0px -10px 8px -4px rgba(2, 6, 23, 0.03), 0px 13px 24px -4px rgba(2, 6, 23, 0.08);
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
}

.btn {
    width: 24px;
    height: 24px;
}

.inputFile.inputFile {
    width: auto;
    cursor: pointer;
}

.inputFile.inputFile label {
    min-height: auto;
    gap: 8px;
    font-size: 14px;
}

.inputFile span {
    transition: color .3s ease;
    padding: 0;
}

.inputFile:hover span{
    color: var(--color-text-main);
}

.form {
    display: flex;
    align-items: center;
    width: 100%;
  
}

.inputWr {
    box-shadow: none;
    padding: 0;
    color: var(--color-text-main);
}

.inputWr.inputWr:hover {
    box-shadow: none;
}

.inputWr.inputWr:active{
    border-color: transparent;
}

.input {
    padding: 4px;
    width: 100%;
}

.menu {
    padding: 0 12px;
    border-radius: 6px;
    flex-direction: column;
    border: 1px solid var(--color-light-stroke);
    display: none;
    position: absolute;
    left: 16px;
    top: -80px;
    z-index: 10;
    background: var(--color-white);
}

.active {
    display: flex;
}

.menuRowItem svg path {
    transition: fill .3s ease;
}

.menuRow {
    padding: 8px 4px 8px 0;
    display: flex;
    align-items: center;

    gap: 4px;
    transition: color .3s ease;
    cursor: pointer;
}

.menuRowItem {
    padding: 4px  4px 0 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    transition: color .3s ease;
    cursor: pointer;
}

.menuRow .inputFile svg path {
    cursor: pointer;
}

.menuRowItem .inputFile label {
    transition: color .3s ease;
}

.menuRowItem .inputFile label span{
    cursor: pointer;
    color: #32353E80;
    font-weight: 500;
}

.menuRowItem:hover .inputFile label span{
    color: var(--color-text-main);
}

.menuRow:hover .inputFile svg path{
    fill: #2B2D3E;
}

.menuRow + .menuRow {
    border-top: 1px solid var(--color-light-stroke);
}

.clipBtn {
    width: 32px;
    height: 32px;
}

.answerTo {
    width: 100%;
    background: var(--color-light-stroke);
    font-size: 12px;
    padding: 16px 12px 0;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.answerToWrapper {
    position: relative;
    background: var(--color-white);
    padding: 8px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
}

.answerName {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 4px;
}

.close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
}

.defaultMessage {
    margin-top: 8px;
    padding: 8px;
    text-align: center;
    width: 100%;
    color: #32353E80;
    font-size: 14px;
}


.clipMessage {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-light-stroke);
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color .3s ease;
}

.clipMessage:hover {
    background: #EDE0FF;
}

.clipMessageDate {
    color: var(--color-text-secondary);
    font-size: 11px;
}

.authorName {
    font-weight: 600;
    display: inline-block;
    margin-right: 16px;
    line-height: 2;
    font-size: 14px;
}

.clipMessageText {
    color: var(--color-text-secondary);
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.clipMessageWr {
    display: flex;
    gap: 4px;
    align-items: center;
}

.wrapper {
    overflow-y: auto;
}

.btnScroll {
    position: absolute;
    bottom: 64px;
    right: 16px;
    padding: 4px 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--box-shadow);
    border-radius: 4px;
}

.btnScroll svg{
    transform: rotate(270deg);
}

@media (max-width: 990px) {
    .page {
        /*height: calc(100vh * 0.88);*/
    }
}

@media (max-width: 540px) {

    .header {
        padding: 12px;
        gap: 8px;
    }

    .participants {
        display: none;
    }
}

.submit{
    position: relative;
    transition: all 0.3s;
    margin-left: 0px;
    margin-right: 15px;
}
.noActiveSubmit{
    margin-right: -27px;
    margin-left: 10px;
}
.messageDz{
    display: flex;
    flex-direction: column;
}
.messageDz a{
    text-decoration: underline;
    color: var(--color-accent);
}

.newDayView{
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 11px;
    font-weight: 500;
    color: var(--color-text-secondary);
    padding: 5px 0px;
}