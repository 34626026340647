.chats.chats {
    position: fixed!important;
    background: var(--color-white);
    right: 40px;
    bottom: 16px;
    z-index: 102;
    border-radius: 12px;
    border: 2px solid var(--color-text-main);
    overflow: hidden;
}

.chatBox {
    position: fixed;
    right: 40px;
    bottom: 16px;
    z-index: 100;
    border-radius: 12px;
    border: 2px solid var(--color-text-main);
}

.changeSizeBtn {
    padding: 8px;
    border: 2px solid var(--color-light-stroke);
    width: auto;
    border-radius: 8px;
    background: var(--color-white);
    z-index: 15;
    cursor: pointer;
    transition: border-color .3s ease;
}

.changeSizeBtn:hover {
    border: 2px solid var(--color-text-main);
}

.changeSizeBtn svg path {
    transition: fill .3s ease;
}

.changeSizeBtn:hover svg path {
    fill: var(--color-text-main);
}


@media (max-width: 1439px) {
    .chats.chats {
        right: 20px;
    }
}
@media (max-width: 992px) {
    .chats.chats {
        bottom: 64px;
    }
}

@media (max-width: 579px) {
    .chats.chats {
        right: 8px;
    }
}