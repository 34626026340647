.wrapper {
  display: inline-block;
  position: relative;
}

.hint {
  min-height: 90px;
  position: absolute;
  width: 410px;
  top: 50%;
  left: calc(100% + 20px);
  transform: translateY(-50%) translateX(-20px);
  padding: 20px;
  border-radius: 27px;
  background-color: #ffffff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  box-shadow: 2px 0 4px 0 rgba(0 0 0 / 20%);
  z-index: 5;
}

.hint::after {
  right: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-right-color: #ffffff;
	border-width: 20px;
	margin-top: -20px;
}

.wrapper:hover .hint {
  transform: translateY(-50%);
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1279px) {
  .hint {
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%) translateY(-20px);
  }

  .hint::after {
    right: auto;
    top: auto;
    bottom: 100%;
	  left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: #ffffff;
    border-width: 20px;
	  margin-left: -20px;
  }

  .wrapper:hover .hint {
    transform: translateX(-50%) translateY(0);
  }
}
