.custom-radio-element .ant-select-selector .ant-select-selection-search  .ant-select-selection-search-input{
    height: auto;
}

.custom-radio-element .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
    max-height: 32px;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid var(--color-light-stroke);
    box-shadow: none;
    min-width: 132px;
    font-size: 12px;
}

.custom-radio-element  .custom-dropdown {
    font-size: 12px;
}

.ant-select-item-option-content  {
    font-size: 14px!important;
    color: var(--color-text-main);
    line-height: 1.2;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #AB48E0!important;;

}

.ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-customize-input) .ant-select-open .ant-select-selector{
    border-color: #AB48E0!important;
    box-shadow: none!important;
}

.ant-select-focused:where(.css-dev-only-do-not-override-18lpp2z).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    box-shadow: none!important;
}


.ant-select-selection-item {
    font-size: 16px!important;
    font-weight: bold;
    overflow-wrap: break-word !important;
    white-space: normal !important;
    color: var(--color-text-main);
}

.ant-select-selector {
    transition: border-color .3s ease;
    height: 100% !important;
    padding: 8px!important;
    width: 180px!important;
}


.ant-select-selector:hover {
    border-color: #AB48E0!important;
}

.ant-select-open {
    border-color: #AB48E0!important;
}

:where(.css-dev-only-do-not-override-89pcxa).ant-image .ant-image-mask:hover{
    border-radius: 50%;
}