.wrapper {
  display: inline-block;
  width: min(250px, 100%);
}

.inputBox {
  background-color: white;
  display: inline-block;
  width: 100%;
  box-shadow: 0px 0px 10px rgba(0 0 0 / 0.25);
  border-radius: 16px;
  border: 1px solid transparent;
  transition: color 0.3s, background 0.3s, border 0.3s, box-shadow 0.3s;
}

.inputBox:not(.disabled):hover {
  box-shadow: 0 0 16px var(--color-accent);
}

.inputBox:not(.disabled).onlyIcon:hover {
  box-shadow: none;
}

.inputBox:not(.disabled) .ico:hover {
  color: var(--color-accent);
}

.inputBox:not(.disabled):active {
  border-color: var(--color-accent);
  box-shadow: var(--box-shadow-active);
}

.inputBox.error {
  border-color: var(--color-accent-first);
}

.inputBox.disabled {
  box-shadow: 0px 0px 10px rgba(119, 119, 119, 0.25);
}

.input {
  width: 100%;
  background-color: white;
  color: var(--color-black);
  font-weight: 500;
  font-size: 16px;
  border: none;
  padding: 18px 20px;
  border-radius: inherit;
}

/* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type=number] {
  -moz-appearance: textfield;
}

.input::placeholder,
.placeholder {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
}

.title {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 13px;
  color: var(--color-gray);
}

.errorText {
  margin-top: 5px;
  font-weight: 500;
  font-size: 13px;
  color: var(--color-accent-first);
}

.errorText:not(.show) {
  display: none;
}

.ico {
  flex: 0 0 24px;
  margin-left: auto;
  width: 24px;
  height: 24px;
}

@media (max-width: 992px) {
  .input {
    padding: 10px 20px;
    font-size: 12px;
  }
}

/* ------ */
div.selectDays {
  width: 100%;
}

div.selectDays>div {
  box-shadow: 0px 0px 10px rgb(119 119 119 / 25%);
  border-radius: 16px;
  border: 1px solid transparent;
}

.selectDays>div>span,
.selectDays>div>input::placeholder {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
}

.selectDays.value>div>span,
.selectDays>div>input {
  color: black;
  font-weight: 500;
  font-size: 16px;
}

.selectDays.error>div {
  border-color: var(--color-accent-first);
}

@media (max-width: 992px) {
  .selectDays>div>input {
    font-size: 12px;
  }
}





.inputBoxFile {
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
}

.inputBoxFile:not(.disabled) {
  cursor: pointer;
}

.inputBoxFile .input {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px;
}

.inputBoxFile a {
  width: auto;
  text-decoration: underline;
  color: var(--color-accent)
}

.inputFileWrap {
  display: flex;
  gap: 2px;
  font-size: 10px;
  padding: 2px;
  background-color: var(--color-blue-gray);
  border-radius: 5px;
}

.inputFileWrap .ico {
  flex: 0 0 15px;
  align-items: center;
  width: 15px;
  height: 15px;
}


.inputFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.inputFile {
  display: none;
}






.InputBoxSelect {
  position: relative;
  min-height: 56px;
  display: flex;
  align-items: center;
  padding: 5px 20px;
  cursor: pointer;
}

.InputBoxSelect.disabled {
  cursor: default;
}

@media (max-width: 992px) {
  .InputBoxSelect {
    min-height: 35px;
    font-size: 12px;
  }
}

.InputBoxSelect .ico {
  transition: 0.3s;
}


.InputBoxSelect .input {
  padding: 0;
}

.selectListWrap {
  width: 100%;
  top: calc(100% + 10px);
  left: 0;
  position: absolute;
  min-height: 100px;
  max-height: 190px;
  z-index: 1060;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 0px 10px rgba(0 0 0 / 0.25);
  overflow: hidden;
  padding: 15px 5px 15px 0;
  opacity: 0;
  visibility: hidden;
  transform: scale(0) translateY(100%);
  transition: 0.3s;
}

.selectListWrap.large {
  max-height: 350px;
}


.selectList {
  overflow-y: auto;
  max-height: calc(190px - 30px);
  padding: 0 5px;
  display: grid;
  gap: 5px;
}

.selectList.large {
  max-height: calc(350px - 30px);
}

.textError {
  display: block;
  padding: 5px;
  color: var(--color-gray);
}

.selectItem {
  display: block;
  padding: 5px;
  border-radius: 10px;
}

.selectItem.selected {
  background-color: var(--color-accent);
  color: white !important;
}

.selectItem:hover {
  background-color: var(--color-accent);
  color: white;
}

.InputBoxSelect.show .ico {
  transform: scale(1, -1);
}

.selectListWrap.show {
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  visibility: visible;
}


.InputBoxCopy {
  display: flex;
  align-items: center;
  padding-right: 20px;
  cursor: pointer;
}

.InputBoxCopy input {
  cursor: pointer;
}

.icoIcon {
  margin-left: 0;
}

.onlyIcon {
  min-height: 0;
  box-shadow: none;
}

.newDesign {
  box-shadow: none;
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.newDesign.wrapper {
  width: auto;
}

.newDesign.inputBox:not(.disabled):hover,
.newDesign.inputBox:not(.disabled):active
{
  box-shadow: none;
}

.newDesign .ico {
  margin-left: 0;
}

@media (max-width: 992px) {
  .onlyIcon {
      padding: 0 10px;
  }
}

.icoNewDesign.icoNewDesign {
  margin-left: auto;
  fill: var(--color-blue-gray);
  cursor: pointer;
  transition: fill .3s ease;
  width: 16px;
  height: 16px;
}

.icoNewDesign:hover{
  fill: var(--color-text-main);
}
.icoNewDesign{
  margin: 0px;
  width: 0.85rem !important;
  height: 0.45rem !important;
}
.inputFinContentWidth{
  width: fit-content;
  min-width: fit-content;
}
.inputFinContentWidth .placeholder{
  text-wrap: nowrap;
}
@media (min-width: 1481px) {
  .inputFinContentWidth{
    min-width: 12rem;
  }
}

@media (max-width: 1280px) {
  .icoNewDesign{
    width: calc(0.85rem * 1.3) !important;
    height: calc(0.45rem * 1.3) !important;
  }
}
@media (max-width: 992px) {
  .icoNewDesign{
    width: calc(0.85rem * 1.6) !important;
    height: calc(0.45rem * 1.6) !important;
  }
}
@media (max-width: 767px) {
  .icoNewDesign{
    width: calc(0.85rem * 3.3) !important;
    height: calc(0.45rem * 3.3) !important;
  }
  .inputFinContentWidth{
    width: calc(33.333% - 5px);
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.selectListWrapNewDesign{
  width: fit-content;
  min-width: 100%;
}
.disableInputSelect{
  opacity: 0.7;
  pointer-events: none;
}