.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
  align-items: center;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}