.wrapper {
  display: grid;
  grid-template-columns: 150px 700px;
  gap: 10px;
}

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.row {
  position: relative;
  /*display: grid;*/
  /*grid-template-columns: repeat(2, 1fr);*/
  margin-bottom: 10px;
  /*gap: 20px;*/
  align-items: flex-start;
}

.inputNew {
  width: 100%;
}

.input {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.input {
  margin-bottom: 10px;
}

.btnRemove {
  position: absolute;
  top: 10px;
  right: -30px;
  width: 24px;
  height: 24px;
}


.textarea {
  box-shadow: var(--box-shadow);
}

.textarea,
.textarea~div {
  font-weight: 500;
  font-size: 11px;
  min-height: 55px;
}

.inputWrapper {
  display: grid;
  grid-template-columns: 1fr 40px;
  align-items: center;
  padding-right: 10px;
  gap: 10px;
}


.btnImg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  box-shadow: var(--box-shadow-light);
  border-radius: 50%;
  color: var(--color-gray);
  transition: all 0.3s;
  background-color: var(--color-blue-gray);
}

.btnImg>svg {
  width: 12px;
  height: 12px;
}

.btnImg:hover {
  background-color: var(--color-white);
}

.btnImg:active {
  box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}