.input,
.boobleN {
  display: inline-flex;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 16px;
  min-height: var(--booble-height);
  padding: 0 32px;
  border: 1px solid transparent;
  box-shadow: var(--box-shadow);
  font-weight: 700;
  font-size: 14px;
}

.input.text-center,
.boobleN.text-center {
  justify-content: center;
  text-align: center;
  padding: 0;
}

.input::placeholder {
  font-weight: 400;
}

.boobleN-gray-text {
  margin-left: auto;
  color: var(--color-gray);
}
