.button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
}
.button span {
    position: absolute;
    top: -5px;
    right: -13px;
}
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.numberOfNotifications {
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #AB48E0;
    position: absolute;
    top: -2px;
        border-radius: 50%;
    right: 0px;
    line-height: 0;
    font-size: 9px;
    color: #fff;
    font-weight: 600;
    font-family: 'Inter';
    animation: none;
}

.numberOfNotifications.pulse {
    animation: pulse 1s infinite;
}
.close {
    position: absolute;
    right: 12px;
    top: 24px;
    cursor: pointer;
}
.title{
    font-weight: 600;
    font-size: 20px;
    color: var(--color-text-secondary);
    padding: 24px;
    border-bottom: 1px solid #F4F4F4;
}
.close svg{
    width: 20px;
    height: auto;
}
.notificationsList{
    padding: 24px;
    padding-top: 0px;
    display: flex;
    flex-direction: column;
}
.notificationsListItem{
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px 0px 16px 0px;
    border-bottom: 1px solid #F4F4F4;
}
.notificationsListItemTitle{
    font-weight: 600;
    font-size: 16px;
    color: var(--color-text-secondary);
}
.notificationsListItemMessage{
    margin-top: 8px;
    font-size: 14px;
    color: var(--color-text-secondary);
}
.notificationsListItemDate {
    color: var(--color-time);
    font-size: 12px;
    font-weight: 500;
    margin-top: 8px;
    display: flex;
    justify-content: flex-end;
}

.noNotifications {
    margin-top: 8px;
    margin-left: 10px;
    font-size: 14px;
    color: var(--color-text-secondary);
}
.unreadDot {
    position: absolute;
    top: 10px;
    left: -15px;
    width: 8px;
    height: 8px;
    background-color: purple;
    border-radius: 50%;
}

.unread {
    background-color: aliceblue;
}