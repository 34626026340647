
.textarea {
  max-width: 600px;
}

.textareaCorrection {
  margin: 0;
}

.textarea:disabled {
  background-color: white;
}

.textarea ~ div {
  max-width: 600px;
}



.answerWrap {
  /* max-width: 600px; */
  display: grid;
  row-gap: 10px;
}

.capsuleLeft, .capsuleRight {
  box-shadow: var(--box-shadow-light);
  font-size: 12px;
  min-height: 33px;
  padding-right: 20px;
  padding-left: 20px;
  min-width: 150px;
  border-radius: 16px ;
}

.capsuleLeft {
  width: auto;
  position: relative;
  z-index: 2;
  background-color: var(--color-accent);
  color: white;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.capsuleRight {
  position: relative;
  z-index: 1;
  background-color:white;
  font-weight: 500;
  padding-top: 15px;
  padding-bottom: 15px;

}

.red {
  background-color: var(--color-accent-first);
}

.upload {
  display: block !important;
  width: max-content;
  margin-bottom: 5px;
}

.estimation {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 30px;
}

.booble {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 55px;
  min-width: 55px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  box-shadow: var(--box-shadow);
  text-align: center;
}
@media (max-width: 992px) {
  .upload {
    margin-bottom: 10px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  width: 600px;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}


.actionContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}
.editable{
  max-width: 300px;
}
.editable:hover{
  box-shadow: var(--box-shadow-light);
  border-radius: 15px;
  transition: all 0.3s;
  cursor: pointer;
}

.canvasManage{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  background: #fff;
  padding: 15px;
  user-select: none;
  margin-top: 10px;
  border-radius: 15px;
}
.inputWrapper{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.inputsWrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.textСlue{
  font-size: 13px;
}
.canvas{
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 15px;
  border: 3px solid #fff;
  padding: 15px;
  cursor: url(./icon.png), auto;
}
.buttonDisable{
  opacity: 0.5;
  pointer-events: none;
}
.canvasAndIcoRemove{
  display: flex;
  width: fit-content;
  position: relative;
  max-width: calc(100vw - 400px);
  overflow: auto;
}
.canvasAndIcoRemove svg{
  position: absolute;
  top: 15px;
  right: 10px;
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.preloader{
  position: absolute;
  width: 100%;
  height: calc(100% - 16px);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  top: 6px;
  left: 0px;
  border-radius: 15px;
  z-index: 10;
}
.preloader svg{
  position: relative;
  top: 0px;
  left: 0px;
  right: 0;
}
.canvasWrapperHide{
  display: none;
}
.textСlueBig{
  font-weight: 700;
  font-size: 20px;
}
.tooltipWrapper{
  display: flex;
  align-items: center;
  position: relative;
  gap: 5px;
}
.tooltipWrapper svg{
  position: relative;
  top: 2px;
}
.tooltipWrapper svg path{
  fill: #000;
}
.canvasInputFont {
  width: 180px;
}
.inputText{
  font-family: serif;
  background: transparent;
  padding-left: 5px;
  font-family: 'Montserrat', sans-serif;
  border-top: 2px solid #b18484;
  border-right: 2px solid #e8a9a9;
  border-bottom: 2px solid #6e6161;
  border-left: 2px solid #000;
}
@media (max-width: 992px) {
  .textСlueBig{
    font-size: 18px;
  }
}

.buttonText{
  width: fit-content;
}