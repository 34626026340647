.page {
  --margin: 20px;
  margin-right: var(--gap);
}

.headRow {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: var(--margin);
}

.tabs {
  flex-direction: column;
}

.buttonReset {
  max-width: 150px;
  width: 100%;
}

@media (max-width: 992px) {
  .page {
    --margin: 15px;
    margin-right: 0;
  }
}


/* фильтр */
.select {
  max-width: 250px;
  width: 100%;
  flex: 1 0 150px;
}

@media (max-width: 992px) {
  .tabs {
    flex-direction: row;
  }
}



.listBooble {
  margin-bottom: var(--margin);
  display: grid;
  gap: 5px;
}

.listBooble li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.booble {
  display: inline-block;
  height: 20px;
  width: 40px;
  border-radius: 5px;
}

.booble.yellow {
  background-color: var(--color-yellow);
}

.booble.red {
  background-color: var(--color-accent-first);
}

.booble.accent {
  background-color: var(--color-accent);
}
