.wrapper {
  height: calc(100vh - 300px);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.bg {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100vw - 290px);
  height: calc(100vh - 350px);
}

.bg img {
  max-width: 100%;
  max-height: min(100%, 550px);
}

.descr {
  font-weight: 500;
  font-size: 18px;
  max-width: 250px;
}

@media (max-width: 992px) {
  .bg {
    width: 100vw;
    bottom: 60px;
  }
}
