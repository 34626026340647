.timer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.time {
  font-weight: 400;
  font-size: 36px;
}


@media (max-width: 768px) {
  .timer button {
    min-width: 150px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .time {
    font-size: 22px;
  }

}