.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
  align-items: center;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.title{
  flex-shrink: 0;
}
.input{
  border-radius: 8px;
  box-shadow: none !important;
  min-height: 44px !important;
  outline: none !important;
  padding-top: 5px;
  padding-bottom: 5px;
  width: fit-content;
}