.wrapper {
  display: inline-block;
}

.wrapper.fullScreenContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  height: 50vh;
}

.wrapper.fullScreenCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}


.fullScreenContainer .ico,
.fullScreenCenter .ico {
  width: 100px;
  height: 100px;
}


.ico:not(.dark) {
  stroke: var(--color-accent);
}