.row {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 12px 16px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.defaultImage {
    width: 48px;
    height: 48px;
    background: var(--color-light-stroke);
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.selectContainer {
    display: inline-flex;
    align-items: center;
}
.select {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 3px solid var(--color-light-stroke);
}

.controls {
    margin-left: auto;
    display: flex;
    gap: 16px;
}

.controls .customSelect .ant-select-selector {
    height: 40px;
}

.customSelect + .ant-select-selector {
    padding: 5px;
    height: auto;
}

.selected {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2x solid var(--color-light-stroke);
    background: #AB48E0;
}

.rowImage {
    max-width: 24px;
}

.img::before {
    background-size: 100%;
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99984 3.33335C5.42251 3.33335 3.33317 5.42269 3.33317 8.00002C3.33317 9.21416 3.79683 10.32 4.55675 11.1501C4.81999 10.6756 5.2227 10.2663 5.72335 9.95711C6.38251 9.54998 7.18243 9.33335 7.9993 9.33335C8.81618 9.33335 9.6161 9.54998 10.2753 9.95711C10.7762 10.2665 11.179 10.676 11.4423 11.1509C12.2026 10.3206 12.6665 9.21451 12.6665 8.00002C12.6665 5.42269 10.5772 3.33335 7.99984 3.33335ZM10.9363 11.6272C10.7423 11.2011 10.3967 10.8157 9.92492 10.5243C9.37871 10.1869 8.70176 10 7.9993 10C7.29685 10 6.61989 10.1869 6.07368 10.5243C5.6021 10.8156 5.2566 11.2007 5.0626 11.6266C5.86466 12.277 6.88674 12.6667 7.99984 12.6667C9.11258 12.6667 10.1344 12.2772 10.9363 11.6272ZM2.6665 8.00002C2.6665 5.0545 5.05432 2.66669 7.99984 2.66669C10.9454 2.66669 13.3332 5.0545 13.3332 8.00002C13.3332 10.9455 10.9454 13.3334 7.99984 13.3334C5.05432 13.3334 2.6665 10.9455 2.6665 8.00002ZM7.99992 5.55555C7.26354 5.55555 6.66659 6.1525 6.66659 6.88888C6.66659 7.62526 7.26354 8.22221 7.99992 8.22221C8.7363 8.22221 9.33325 7.62526 9.33325 6.88888C9.33325 6.1525 8.7363 5.55555 7.99992 5.55555ZM5.99992 6.88888C5.99992 5.78431 6.89535 4.88888 7.99992 4.88888C9.10449 4.88888 9.99992 5.78431 9.99992 6.88888C9.99992 7.99345 9.10449 8.88888 7.99992 8.88888C6.89535 8.88888 5.99992 7.99345 5.99992 6.88888Z" fill="%23C5C6D9"/></svg>');
}

.btn {
    cursor: pointer;
}

.btn svg path {
    transition: fill .3s ease;
}

.btn:hover svg path {
    fill: var(--color-text-main)
}

.image {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    background-color: var(--color-light-stroke);
}

.image img::before {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.99984 3.33335C5.42251 3.33335 3.33317 5.42269 3.33317 8.00002C3.33317 9.21416 3.79683 10.32 4.55675 11.1501C4.81999 10.6756 5.2227 10.2663 5.72335 9.95711C6.38251 9.54998 7.18243 9.33335 7.9993 9.33335C8.81618 9.33335 9.6161 9.54998 10.2753 9.95711C10.7762 10.2665 11.179 10.676 11.4423 11.1509C12.2026 10.3206 12.6665 9.21451 12.6665 8.00002C12.6665 5.42269 10.5772 3.33335 7.99984 3.33335ZM10.9363 11.6272C10.7423 11.2011 10.3967 10.8157 9.92492 10.5243C9.37871 10.1869 8.70176 10 7.9993 10C7.29685 10 6.61989 10.1869 6.07368 10.5243C5.6021 10.8156 5.2566 11.2007 5.0626 11.6266C5.86466 12.277 6.88674 12.6667 7.99984 12.6667C9.11258 12.6667 10.1344 12.2772 10.9363 11.6272ZM2.6665 8.00002C2.6665 5.0545 5.05432 2.66669 7.99984 2.66669C10.9454 2.66669 13.3332 5.0545 13.3332 8.00002C13.3332 10.9455 10.9454 13.3334 7.99984 13.3334C5.05432 13.3334 2.6665 10.9455 2.6665 8.00002ZM7.99992 5.55555C7.26354 5.55555 6.66659 6.1525 6.66659 6.88888C6.66659 7.62526 7.26354 8.22221 7.99992 8.22221C8.7363 8.22221 9.33325 7.62526 9.33325 6.88888C9.33325 6.1525 8.7363 5.55555 7.99992 5.55555ZM5.99992 6.88888C5.99992 5.78431 6.89535 4.88888 7.99992 4.88888C9.10449 4.88888 9.99992 5.78431 9.99992 6.88888C9.99992 7.99345 9.10449 8.88888 7.99992 8.88888C6.89535 8.88888 5.99992 7.99345 5.99992 6.88888Z" fill="%23C5C6D9"/></svg>');
    background-size: 100%;
    border-radius: 50%;
}

.image img {
    width: 48px;
    height: 48px;
    position: absolute;
    border-radius: 50%;
    left: 0;
}

.image img::after {
    display: none;
}

.option {
    background: black!important;
}

.rowHead {
    display: flex;
    align-items: center;
    gap: 8px;
}

@media (max-width: 540px) {
    .row {
        flex-direction: column;
        align-items: flex-start;
    }

    .rowHead {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .controls {
        margin-left: 0;
        width: 100%;
        justify-content: space-between;
    }
}