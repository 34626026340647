.selectWrapper {
  position: relative;
}

.list {
  position: absolute;
  right: 0;
  background-color: white;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  z-index: 15;
  padding: 10px 5px;
  display: grid;
  gap: 5px;
}

.list:not(.active) {
  display: none;
}

.item {
  border-radius: 16px;
  padding: 7px;
}

.item:not(.active) {
  cursor: pointer;
}

.item:hover,
.item.active {
  background-color: var(--color-accent);
  color: white;
}
