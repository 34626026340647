.selectWrapper {
  position: fixed;
  inset: 0;
  z-index: 200;
  visibility: hidden;
}

.selectWrapper.active {
  visibility: visible;
}

.select.disable,
.select.noActive {
  cursor: default;
}

.select.disable:hover,
.select.noActive:hover  {
  box-shadow: var(--box-shadow);
}

.select.disable:active {
  border-color: transparent;
}

.select.disable>span {
  color: var(--color-gray);
}


.select {
  position: relative;
  display: inline-flex;
  align-items: center;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 16px;
  padding: 18px 43px;
  font-weight: 700;
  font-size: 14px;
  user-select: none;
  cursor: pointer;
  transition: box-shadow 0.3s;
  text-align: start;
}


.select--noArrow {
  padding-right: 43px;
}

.select>span {
  width: 100%;
  position: relative;
  padding-right: 20px;
}

.select--noArrow>span {
  padding-right: 0;
}

.select>span>svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  fill: inherit;
  transition: 0.3s;
}

.hideWrapper {
  position: absolute;
  transform: scale(0) translateY(100%);
  visibility: hidden;
  transition: 0.3s;
  min-width: 100%;
  z-index: 1;
  top: calc(100% + 10px);
  left: 0;
  background: var(--color-white);
  box-shadow: var(--box-shadow);
  padding: 10px;
  border-radius: 16px;
  user-select: none;
}

.selectList {
  /* max-width: 350px; */
  max-height: 200px;
  overflow-y: auto;
  cursor: pointer;
}

.selectList>li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px;
  margin-right: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: auto;
  border-radius: 16px;
}

.selectList>li:hover {
  background-color: var(--color-accent);
  color: var(--color-white);
}

.select.active::before {
  content: '';
  position: fixed;
  inset: 0;
  z-index: 200;
  cursor: pointer;
}

.select.active>span>svg {
  transform: scale(1, -1);
}

.select.active .hideWrapper {
  transform: scale(1);
  visibility: visible;
  z-index: 201;
}


@media (max-width: 992px) {
  .select {
    padding: 0 10px 0 10px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    min-height: 35px;

  }

  .select>svg {
    right: 20px;
  }
}

@media (max-width: 768px) {
  .hideWrapper {
    max-width: 100%;
  }
}
