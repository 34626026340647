.pageNews{
    display: flex;
    flex-direction: column;
    align-items:center;
}
.pageNews header{
    margin-right: auto;
    margin-left: 30px;
    margin-top: 0px;
}
.page h2{
    font-size: 30px;
    width: 100%;
    text-align: center;
    margin: 0px;
}
.page p *{
    font-size: 18px;
}
.page p{
    margin: 0px;
    font-size: 18px;
    white-space: pre-line;
}
.page h2 *{
    font-size: 30px;
}
.page h3{
    width: 100%;
    margin: 0px;
    text-align: center;
    font-size: 24px;
}

.page h3 *{
    font-size: 24px;
}
.page *{
    color: #000;
}


.page{
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: calc(100% - 60px);
}
.img{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.imgLeftText{
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.imgLeft{
    max-width: calc(50% - 10px);
}
.imgText{
    width: calc(50% - 10px);
    display: flex;
    align-items: center;
}
.img img{
    border-radius: 12px;
}
.imgLeft img{
    border-radius: 12px;
    width: 100%;
}
.videoWrapperFirst{
    margin-left: auto;
    margin-right: auto;
}
.imgRightText{
    width: 100%;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.imgRight{
    max-width: calc(50% - 10px);
}
.imgRight img{
    width: 100%;
    border-radius: 12px;
}

@media(max-width:992px) {
    .page{
        max-width: 100%;
        padding-bottom: 80px;
    }
    
    .page p {
        font-size: 16px;
    }
    .page p *{
        font-size: 16px;
    }

    .page h3 {
        font-size: 21px;
    }
    .page h2 {
        font-size: 25px;
    }
    
    .page h2 *{
        font-size: 25px;
    }
    .page h3 *{
       font-size: 21px;
    }
    
}
@media(max-width: 700px) {
    .page{
        gap: 23px;
    }
    .imgRightText{
        gap: 15px;
    }
    .imgLeftText{
        gap: 15px;
    }
    .imgLeft{
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items:center;
        justify-content: center;
    }
    .imgRight{
        display: flex;
        align-items:center;
        justify-content: center;
        width: 100%;
        max-width: 100%;
    }
    .imgText{
        width: 100%;
    }   
}
.link{
    width: fit-content;
}
.linkBlock{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}



.inputNew{
    width: 100%;
    max-width: 100% !important;
}
.comments{
    width: 100%;
    padding: 20px;
    background: #f9e9e9;
    border-radius: 12px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
.commentsTextarea{
    width: 100%;
}
.commentsTextarea textarea{
    min-height: 80px;
}
.commentsList{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap:30px;
}
.commentsListItem{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
    position: relative;
    background: #fdfdfd;
    border-radius: 12px;
}
.delete{
    position: absolute;
    right: -19px;
    top: -11px;
    z-index: -1;
    pointer-events: none;
    opacity: 0;
    font-size: 12px;
    background: var(--color-accent-first);
    color: #fff;
    transition: all 0.3s;
    padding: 3px 5px;
    user-select: none;
    cursor: pointer;
    border-radius: 5px;
}
.commentsListItem:hover .delete{
    z-index: 1;
    pointer-events: auto;
    opacity: 1;
}
@media(max-width: 992px) {
    .delete{
        z-index: 1;
        pointer-events: auto;
        opacity: 1;
    }
}
@media(max-width: 700px) {
    .delete{
       right: -16px;
    }
}
.commentsListItemAvatar{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.commentsListItemAvatar img{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    overflow: hidden;
    min-width: 32px;
    min-height: 32px;
}
.commentsListItemText{
    width: calc(100% - 52px);
    display: flex;
    flex-direction: column;
    gap:10px;
}
.commentsListItemTextNameAndDate{
    display: flex;
    flex-wrap: wrap;
}
.commentsListItemTextName{
    padding-right: 8px;
    color: rgba(0, 0, 0, .45);
    font-size: 12px;
}
.commentsListItemTextDate{
    line-height: 0.7;
}
.commentsListItemTextDate *{
    color: #ccc;
    font-size: 12px;
}
.commentsListItemTextContext{
    font-size: 14px;
    white-space: pre-line;
}
.numberOfComments{
    width: 100%;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
}
@media(max-width: 700px) {
    .comments {
        padding: 15px;
        gap: 15px;
    }
    .commentsTextarea textarea{
        border-radius: 12px;
    }
    .page button{
        border-radius: 12px;
    }
    .commentsListItem {
        gap: 15px;
        padding: 15px;
    }
    .numberOfComments{
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .commentsList{
        gap:20px;
    }
}
@media(max-width: 700px) {
    .img{
        width: 100% !important;
    }
    .videoWrapperFirst{
        width: 100% !important;
    }
}
.authorized{
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 33px;
    text-align: center;
}
@media(max-width: 700px) {
    .authorized{
        font-size: 27px;
    } 
}