.card {
  max-width: 660px;
  width: 100%;
}

.title {
  margin-bottom: 25px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.buttonLogin {
  width: 100%;
  background-color: #EAEAEA;
}

.buttonLoginVk {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #CDE0F2;
}

.buttonLoginVk>span {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.buttonLoginVkIco {
  width: 36px;
  height: 36px;
}

@media (max-width: 1200px) {
  .buttons {
    grid-template-columns: auto;
  }
}

@media (max-width: 1024px) {
  .card {
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .buttons {
    grid-template-columns: repeat(2, 1fr);
  }

  .buttonLoginVkIco {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 18px;
  }

  .bottomLinks {
    display: flex;
    gap: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .buttons {
    grid-template-columns: auto;
  }
}
.buttonLoginVk:disabled span span{
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}