.iframe {
  width: 560px;
  height: 315px;
}

@media (max-width: 768px) {
  .iframe {
    width: 100%;
    height: 280px;
  }
}
