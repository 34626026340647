.wrapper {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  max-width: 350px;
  width: 100%;
}


.trackTime {
  width: 100%;
  position: relative;
  display: flex;
  border-radius: 24px;
  padding: 18px 0px;
  background-color: var(--color-white)!important;
  box-shadow: var(--box-shadow-light);
  align-items: center;
  background: linear-gradient(to right, var(--color-accent) var(--value), transparent var(--value));
  cursor: pointer;
}

.trackTime .text {
  position: absolute;
  left: 43px;
  text-transform: uppercase;
  background: linear-gradient(to right, var(--color-white) var(--value), var(--color-black) var(--value));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.audio {
  position: absolute;
  top: 100%;
}

.button {
   vertical-align: middle;
}

.button svg {
  width: 24px;
  height: 24px;
}

.timer {
  min-width: 3em;
}

@media (max-width: 768px) {
  .button svg {
    width: 15px;
    height: 15px;
  }
}
