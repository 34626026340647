.blur {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #cfd6db;
    height: 100%;
    opacity: 0.5;
    -webkit-animation: animate-height .3s ease-out forwards;
    animation: animate-height .3s ease-out forwards;
    z-index: 102;
}

.blurOut {
    -webkit-animation: animate-height-out .3s ease-out forwards;
    animation: animate-height-out .3s ease-out forwards;
    animation-delay: .3s;
}

.popup {
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 40px 40px 32px 32px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 12px;
    -webkit-animation: zoom-in .3s ease-out forwards;
    animation: zoom-in .3s ease-out forwards;
    animation-delay: .3s;
    opacity: 1;
    position: relative;
    max-width: 552px;
}

.popupOut {
    -webkit-animation: zoom-out .3s ease-out forwards;
    animation: zoom-out .3s ease-out forwards;
}

.popupWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 103;
}

.popupHeader {
    display: flex;
    font-size: 18px;
    color: var(--color-text-main);
    justify-content: space-between;
    align-items: center;
    padding-bottom: 12px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--color-light-stroke);
}

.actions {
    display: flex;
    gap: 24px;
    justify-content: center;
}

.btn {
    padding: 12px 16px;
    border: 1px solid #F4F4F4;
    border-radius: 12px;
    background: #F4F4F4;
    transition: background-color .3s ease;
}

.close {
    position: absolute;
    right: 8px;
    top: 8px;
}

.btn:hover{
    background: #EDE0FF;
}

.inputFile {
    box-shadow: #999 0 0 2px;
    border-radius: 12px;
    width: 100%;
    cursor: pointer;
}

.inputFile:hover {
    box-shadow: #333 0 0 3px;
}

.inputFile label {
    justify-content: center;
    gap: 8px;
    cursor: pointer;
}

.inputFile label span {
    font-size: 16px!important;
}

.inputFile label svg path,
.inputFile label svg {
    width: 32px!important;
    height: 32px!important;
    cursor: pointer;
}