.wrapper {
  display: grid;
  gap: 20px;
}

.row {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.checkbox {
  width: 24px;
  height: 24px;
  background-size: 15px 15px;
  border-radius: 5px;
  background-image: none;
}
