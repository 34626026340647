.card {
  position: relative;
  padding: 25px 20px;
  border-radius: 8px;
  border: 1px solid #e2dfdf;
  background: #fefefe;
}

.headTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
  row-gap: 5px;
  margin-bottom: 20px;
}

.title {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray);
}

.timer {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-accent);
  margin-left: auto;
}
.status {
  font-size: 12px;
}

.status.incorrect {
  color: var(--color-accent-first);
}

.status.correct {
  color: var(--color-accent);
}

.status.accept {
  color: var(--color-gray);
}


.question {
  /* max-width: 600px; */
  font-weight: 500;
  font-size: 18px;
}

.question ol {
  margin-left: 20px;
}

.question strong {
  font-weight: 500;
}

.question span {
  font-weight: inherit;
  font-size: inherit;
}

.head {
  display: grid;
  grid-template-columns: 1fr 230px;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.answers {
  margin-top: 20px;
  margin-bottom: 20px;
}

.addendum {
  margin-top: 20px;
}

.bottomMenu {
  transform: translate(15px, 20px);
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
}


.buttonMove svg {
  width: 20px;
  height: 20px;
}


.balls {
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  align-items: end;
}

.ballTitle {
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 5px;
}

.booble {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  background-color: var(--color-accent);
  color: var(--color-white);
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
}

.booble.input {
  border: none;
  text-align: center;
}

.btnFn {
  width: 23px;
  height: 23px;
}

.checkbox {
  margin-top: 10px;
  transform: translate(-15px, -15px);
}

@media (max-width: 768px) {
  .checkbox {
    transform: translate(0px, -10px);
  }

  .balls {
    max-width: 185px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .booble {
    min-height: 35px;
    font-size: 12px;
  }

  .title {
    font-size: 12px;
    margin-bottom: 0;
  }

  .subtitle {
    margin-left: 10px;
  }

  .question {
    max-width: 100%;
    font-size: 14px;
  }

  .headHeader {
    display: grid;
    grid-template-columns: 1fr 180px;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .head {
    gap: 10px;
    grid-template-columns: auto;
    margin-bottom: 10px;
    align-items: flex-start;
  }

  .answers {
    margin-bottom: 10px;
  }

  .bottomMenu {
    transform: translateY(5px);
    gap: 5px;
  }
  .card {
    padding: 15px 15px;
  }
}