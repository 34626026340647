.range {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
  height: 30px;
  -webkit-appearance: none;
  cursor: pointer;
  background: transparent;
}


.rangeWrapper {
  position: relative;
  --colorBg: var(--color-blue-gray);
  --color: var(--color-accent);
}

/*progress support*/
.range {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 30px + var(--ratio) * (100% - 30px));
}

.value {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 30px + var(--ratio) * (100% - 30px));
}

.range+.value {
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  content: var(--values);
  color: var(--color-black);
  top: 30px;
  left: calc(var(--sx) - 15px);
}

/*webkit*/
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--color);
  border: none;
  box-shadow: none;
  margin-top: calc(6px * 0.5 - 30px * 0.5);
}

.range::-webkit-slider-runnable-track {
  height: 6px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(var(--color), var(--color)) 0/var(--sx) 100% no-repeat, var(--colorBg);
  box-shadow: none;
}

/*mozilla*/
.range::-moz-range-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--color);
  border: none;
  box-shadow: none;
}

.range::-moz-range-track {
  height: 6px;
  border: none;
  border-radius: 6px;
  background: linear-gradient(var(--color), var(--color)) 0/var(--sx) 100% no-repeat, var(--colorBg);
  box-shadow: none;
}

/*ms*/
.range::-ms-fill-upper {
  height: 6px;
  border-radius: 6px 0 0 6px;
  margin: -undefined 0 -undefined -undefined;
  background: var(--color);
  border: none;
  border-right-width: 0;
}

.range::-ms-fill-lower {
  background: transparent;
  border-color: transparent;
}

.range::-ms-thumb {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--color);
  border: none;
  box-shadow: none;
  margin-top: 0;
  box-sizing: border-box;
}

.range::-ms-track {
  height: 6px;
  border-radius: 6px;
  /*background: var(--colorBg);*/
  border: none;
  box-shadow: none;
  box-sizing: border-box;
}






/* level browser inconsistencies */
.rangeBt {
  font-weight: 400;
  font-size: 16px;
  --min: 0;
  /* default minimum */
  --max: 100;
  /* default maximum */
  /* suffix */
  --track-w: 148px;
  --uu: calc(var(--track-w) - 1em)/(var(--max) - var(--min));
  display: grid;
  align-content: center;
  position: relative;
  width: var(--track-w);
  max-width: 100%;
  height: 0.5em;
  border-radius: 7px;
  background: var(--color-blue-gray);
  cursor: pointer;
  margin-bottom: 16px;
}

.rangeBt::before,
.rangeBt::after {
  --i: 0;
  --noti: calc(1 - var(--i));
  --sgni: calc(1 - 2*var(--i));
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: calc(0.5em + (var(--noti)*(var(--a) - var(--min)) + var(--i)*(var(--b) - var(--min)))*var(--uu));
  width: calc(var(--sgni)*(var(--b) - var(--a))*var(--uu));
  background: var(--color-accent);
  content: "";
}

.rangeBt::after {
  --i: 1;
}

.rangeBt:focus-within {
  filter: none;
}


.rangeBt input,
.rangeBt output {
  --hl: 0;
  --nothl: calc(1 - var(--hl));
}

.rangeBt input {
  grid-area: 1/1;
  position: relative;
  /* ugh, Edge */
  z-index: calc(1 + var(--hl));
  width: var(--track-w);
  min-height: 2em;
  background: none;
}

.rangeBt input,
.rangeBt input::-webkit-slider-thumb,
.rangeBt input::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}

.rangeBt input::-webkit-slider-runnable-track {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em;
  background: transparent;
  color: transparent;
}

.rangeBt input::-moz-range-track {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em;
  background: transparent;
  color: transparent;
}

.rangeBt input::-ms-track {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em;
  background: transparent;
  color: transparent;
}

.rangeBt input::-webkit-slider-thumb {
  box-sizing: border-box;
  margin-top: calc(.5*(0.5em - 1.3em));
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: var(--color-accent);
  pointer-events: auto;
  cursor: ew-resize;
}

.rangeBt input::-moz-range-thumb {
  box-sizing: border-box;
  margin-top: 0;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: var(--color-accent);
  pointer-events: auto;
  cursor: ew-resize;
  border: none;
}

.rangeBt input::-ms-thumb {
  box-sizing: border-box;
  margin-top: 0;
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background: var(--color-accent);
  pointer-events: auto;
  cursor: ew-resize;
  border: none;
}

.rangeBt input:only-of-type {
  --pos: calc(0.5em + (var(--c) - var(--min))*var(--uu));
}

.rangeBt input:only-of-type::-webkit-slider-runnable-track {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em 0 0 0.25em;
  background: linear-gradient(var(--color-accent), var(--color-accent)), repeating-linear-gradient(135deg, transparent, transparent 4px, #027bc1 5px, #027bc1 7px), var(--color-blue-gray);
  background-repeat: no-repeat;
  background-size: var(--pos) 100%;
}

.rangeBt input:only-of-type::-moz-range-progress {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em 0 0 0.25em;
  background: linear-gradient(var(--color-accent), var(--color-accent)), repeating-linear-gradient(135deg, transparent, transparent 4px, #027bc1 5px, #027bc1 7px), var(--color-blue-gray);
}

.rangeBt input:only-of-type::-ms-fill-lower {
  border: none;
  width: 100%;
  height: 0.5em;
  border-radius: 0.25em 0 0 0.25em;
  background: linear-gradient(var(--color-accent), var(--color-accent)), repeating-linear-gradient(135deg, transparent, transparent 4px, #027bc1 5px, #027bc1 7px), var(--color-blue-gray);
}

.rangeBt input:not(:only-of-type) {
  pointer-events: none;
}

.rangeBt input:not(:only-of-type)::-ms-fill-lower {
  display: none;
}

.rangeBt output[for] {
  --ar: 0;
  --pos: calc((var(--c) - var(--min))*var(--uu));
  position: absolute;
  z-index: var(--hl);
  top: 100%;
  left: 0.5em;
  transform: translate(calc(var(--pos) - 50%));
  counter-reset: c var(--c);
}

.rangeBt output[for]::after {
  font-size: 12px;
  display: block;
  margin-bottom: 0.375em;
  padding: 0.8em;
  color: black;
  filter: grayScale(var(--nothl));
  --mask:
    linear-gradient(red, red) padding-box,
    conic-gradient(from -35deg at 50% 100%,
      red 70deg, transparent 0%) 50% 100%/ 100% calc(var(--ar)*.75em) no-repeat border-box;
  -webkit-mask: var(--mask);
  mask: var(--mask);
  content: counter(c);
}

@supports (background: conic-gradient(from 1deg at 0%, red 9%, tan 0%)) {
  .rangeBt output[for] {
    --ar: 1;
  }
}
