.colorList {
  padding: 10px 20px;
  width: 270px;
  display: inline-grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 7px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow);
  border-radius: 16px;
  list-style: none;
}

.colorItem {
  height: 28px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-light);

}

.colorItem:hover {
  box-shadow: var(--box-shadow);
}

.colorItem.active {
  box-shadow: 0 0 4 16px (var(--color-accent));
}
