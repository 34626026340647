.wrapper {
  display: grid;
  grid-template-columns: 150px 440px;
  gap: 10px;
}
@media (max-width: 767px) {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.input {
  width: 100%;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 30px;
}

.btnRemove {
  position: absolute;
  right: -30px;
  top: 15px;
}

.list {
  display: grid;
  gap: 20px;
}

.col {
  position: relative;
  display: grid;
  gap: 5px;
}

.textarea {
  box-shadow: var(--box-shadow);
}
