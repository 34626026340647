.create-chat .ant-select {
    border: none;
}

.create-chat .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    box-shadow: none;
    border: none;
    outline: none;
}

.create-chat .ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
    border: none!important;
}

.create-chat :where(.css-dev-only-do-not-override-12ud1dt).ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border-color: white;
    box-shadow: none;
}

.chat-info .ant-input,
.create-chat .ant-input {
    box-shadow: none;
    border: none;
    padding-left: 0;
    font-weight: 600;
    color: var(--color-text-main)
}

.chat-info .ant-input:disabled,
.create-chat .ant-input:disabled {
    background-color: white;
}

.react-input-emoji--container.react-input-emoji--container  {
    margin: 5px 0;
}

.react-input-emoji--placeholder.react-input-emoji--placeholder {
    left: 8px;
}

.react-input-emoji--button__show.react-input-emoji--button__show svg {
    fill: #AB48E0;
}

.new-design-scroll {
    overflow: auto;
}

.new-design-scroll::-webkit-scrollbar,
.react-input-emoji--input::-webkit-scrollbar {
    width: 2px;
    height: 2px;
}

.new-design-scroll::-webkit-scrollbar-thumb,
.react-input-emoji--input::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background: #AB48E0;
    cursor: pointer;
}

.new-design-scroll::-webkit-scrollbar-track,
.react-input-emoji--input::-webkit-scrollbar-track{
    border-radius: 7px;
    background-color: var(--color-blue-gray);
    cursor: pointer;
}

.new-design-scroll::-webkit-scrollbar,
.react-input-emoji--input::-webkit-scrollbar{
    width: 2px;
    height: 4px;
}

:where(.css-dev-only-do-not-override-1xnq26j).ant-image .ant-image-mask:hover {
    border-radius: 50%;
}