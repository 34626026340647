.wrapper {
  position: relative;
}

.textarea {
  border: none;
  padding: 18px 20px;
  resize: none;
  box-shadow: 0px 0px 10px rgba(0 0 0 / 0.25);
  border-radius: 16px;
  max-width: 510px;
  width: 100%;
  min-height: 150px;
  outline: none;
  overflow-y: auto;
  font-size: 16px;
  font-weight: 500;
  transition: color 0.3s, background 0.3s, border 0.3s, box-shadow 0.3s;
  border: 1px solid transparent;
  box-sizing: border-box;
}

.textarea:disabled {
  background-color: white;
  box-shadow: 0px 0px 10px rgba(119, 119, 119, 0.25);
}

.textarea:not(:disabled):hover {
  box-shadow: 0 0 16px var(--color-accent);
}

.textarea:not(:disabled):active {
  border-color: var(--color-accent);
  box-shadow: var(--box-shadow-active);
}

.textarea.error {
  border-color: var(--color-accent-first);
}

.textarea::placeholder {
  color: var(--color-gray);
  font-weight: 400;
  font-size: 0.9em;
}

div.textarea {
  top: 0;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  z-index: -5;
  word-wrap: break-word;
  padding-top: 20px;
}

.textarea::-webkit-scrollbar {
  width: 5px;
}

.textarea::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.textarea::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--color-accent);
}

.textarea::-webkit-scrollbar {
  width: 5px;
}

@media (max-width: 992px) {
  .textarea {
    padding: 14px 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 125%;
  }
}