.wrapper {
  position: relative;
  display: inline-flex;
  border-radius: 24px;
  padding: 16px 43px;
  background-color: var(--color-white);
  box-shadow: var(--box-shadow-light);
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  height: 54px;
  font-weight: 400;
  font-size: 14px;
  line-height: 163.42%;
}

.wrapper label {
  cursor: pointer;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

.wrapper label::after {
  position: absolute;
  content: '';
  inset: 0;
  cursor: pointer;
}

.wrapper.disable label::after {
  cursor: default;
}

.wrapper span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrapper input {
  display: none;
}

.wrapper svg {
  font-size: 23px;
  width: 1em;
  height: 1em;
  vertical-align: middle;
}

.wrapper button {
  position: relative;
  z-index: 1;
}

.showMode {
  width: 100%;
}

.showMode a::after {
  content: '';
  position: absolute;
  inset: 0;
  cursor: pointer;
}

@media (max-width: 992px) {
  .wrapper {
    height: 35px;
    padding: 10px 20px;
    font-size: 12px;
    line-height: 20px;
  }

  .wrapper svg {
    font-size: 15px;
  }
}
.wrapperAdditionalFeatures{
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}
.audio{
  border: 2px solid #e9afaf;
  min-width: 100%;
  border-radius: 65px;
  overflow: hidden;
}
.audioButton{
  font-size: 12px;
  border: 2px solid #e9afaf;
  border-radius: 8px;
  padding: 5px;
}
.audioTextAndAudioButtonWrapper{
  display: flex;
  gap: 10px;
  align-items: center;
}
.audioText{
  font-size: 12px;
}
.wrapperAdditionalFeaturesActive{
  background: #fff;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e2dfdf;
}