.file {
    width: 100%;
}

.btnRemove {
    margin-left: 10px;
    width: 24px;
    height: 24px;
}

.btnRemove:disabled {
    cursor: default;
}

.list {
    display: grid;
    gap: 10px;
    width: 100%;
}

.row {
    display: flex;
    align-items: center;
}

.btnImg {
    width: 46px;
    height: 46px;
    box-shadow: var(--box-shadow-light);
    border-radius: 50%;
    color: var(--color-gray);
    transition: all 0.3s;
    background-color: var(--color-blue-gray);
}

.btnImg>svg {
    width: 12px;
    height: 12px;
}

.btnImg:disabled {
    cursor: default;
}

.btnImg:hover:not(:disabled) {
    background-color: var(--color-white);
}

.btnImg:active:not(:disabled) {
    box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}

.btnAdd {
    font-weight: 400;
    font-size: 18px;
    background-color: var(--color-blue-gray);
    border-radius: 10px;
    box-shadow: var(--box-shadow-light);
    height: 38px;
    width: 65px
}

.btnAdd:disabled {
    cursor: default;
}

.btnAdd:hover:not(:disabled) {
    background-color: var(--color-white);
}

.btnAdd:active:not(:disabled) {
    background-color: var(--color-white);
    box-shadow: inset 0 0 5px 0 rgba(0 0 0 / 20%);
}