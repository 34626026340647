.list {
  display: grid;
  gap: 30px;
}

.warning {
  background-color: var(--color-accent-first);
  color: white;
  border-radius: 16px;
  padding: 18px 43px;
}
