.search {
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-yellow);
  padding: 14px 43px;
  border-radius: 16px;
  box-shadow: var(--box-shadow);
  gap: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  min-width: 170px;
}

.search input {
  width: 100%;
  outline: none;
  min-width: 10px;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: transparent;
  border: none;

}

.search input::placeholder {
  color: inherit;
  opacity: 1;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.close {
  position: absolute;
  right: 15px;
}

.search svg {
  width: 24px;
  height: 24px;
}

@media (max-width: 992px) {
  .search {
    padding: 6.5px 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
  }
}