.btnAdd{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #F9EDFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btnRemove {
    position: absolute;
    right: -45px;
    top: calc(50% - 18px);
    transform: translateY(-50%);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: #F9EDFF;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
}