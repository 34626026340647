.user {
    padding: 12px 16px;
    display: flex;
    gap: 16px;
    align-items: center;
    border: 1px solid transparent;
    border-bottom: 1px solid var(--color-light-stroke);
    background: var(--color-white);
    transition: background-color .3s ease;
    cursor: pointer;
}

.user:hover{
    background-color: #EDE0FF;
    border: 1px solid #EDE0FF;
}

.name {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
}

.isOnline {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    background: #52FF97;
    right: 0;
    bottom: 0;
}