.rowImage {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    flex-shrink: 0;
}

.img::before {
    background-size: cover;
    background-image: url('../../../../../images/noAvatar.png');
    background-repeat: no-repeat;
}

.img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    border: 1px solid var(--color-light-stroke);
    background: var(--color-white);
    object-fit: cover;
}

.img::after {
    content: '';
}