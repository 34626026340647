.actionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: 768px) {
  .actionWrapper {
    gap: 10px;
  }
}
.clue{
  color: var(--color-black);
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.comment{
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  width: 100%;
}